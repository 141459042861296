import Vue from 'vue';

class Dialog {
	constructor({ router = undefined, store = undefined }) {
		this.router = router;
		this.store = store;
		this.instances = [];
	}

	async open({
		component = null,
		propsData = {},
		data = {},
		watch = {},
		methods = {},
		success = () => {},
		error = () => {},
		...options
	}) {
		let cDialog = await component().then(res => res.default);
		let DialogConstructor = Vue.extend(cDialog);

		let dialog;
		let _instances = this.instances;

		methods.close = function () {
			for (let i = 0; i < _instances.length; i++) {
				if (_instances[i] === dialog) {
					_instances.splice(i, 1);
					break;
				}
			}

			this.$destroy(true);
			this.$el.parentNode?.removeChild(this.$el);
		};

		methods.success = success.bind(this);
		methods.error = error.bind(this);

		watch.visible = function (val) {
			!val && this.close();
		};

		if (propsData.visible == undefined) {
			data.visible = true;
		}

		dialog = new DialogConstructor({
			router: this.router,
			store: this.store,
			propsData,
			data,
			methods,
			watch,
			...options
		});

		this.instances.push(dialog);

		dialog.$mount();
		document.body.appendChild(dialog.$el);

		return dialog;
	}

	closeAll() {
		for (let i = this.instances.length - 1; i >= 0; i--) {
			this.instances[i].close();
		}
	}
}

export default {
	install(Vue, options) {
		Vue.prototype.$dialog = new Dialog(options);
	}
};
