<template>
	<div class="calendar-stepper-wrapper" :class="{ 'calendar-stepper-wrapper--large': large }">
		<SvgIcons name="left" @click="changeCalendarStepper('prev')" />
		<span v-if="$slots.default" class="calendar-stepper-span">
			<slot></slot>
		</span>
		<span v-else class="calendar-stepper-span">{{ formatMonthDate() }}</span>
		<SvgIcons name="right-light" @click="changeCalendarStepper('next')" />
	</div>
</template>

<script setup>
import moment from 'moment';
import { debounce } from '@/utils/api';
import { computed } from 'vue';
import SvgIcons from '@/components/SvgIcons.vue';

const props = defineProps({
	value: {
		type: Date
	},
	large: {
		type: Boolean,
		default: false
	}
});
const emits = defineEmits(['change']);
const innerValue = computed({
	get: () => {
		if (!props.value) return new Date();
		return props.value;
	}
});

const formatMonthDate = () => {
	return moment(innerValue.value).format('YYYY年MM月');
};
const changeCalendarStepper = debounce(function (type) {
	emits('change', type);
}, 120);
</script>

<style lang="less" scoped>
.calendar-stepper-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.svg-icon {
		font-size: 16px;
		color: #4e5969 !important;
		cursor: pointer;
		user-select: none;
		border-radius: 2px;
		transition: all 0.1 ease;

		&:hover {
			background: var(--fill-lighter-color);
		}
	}
	.calendar-stepper-span {
		color: var(--emphasis-color);
		font-family: 'PingFang SC';
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px; /* 128.571% */
	}

	&--large {
		.svg-icon {
			font-size: 24px;
		}
		.calendar-stepper-span {
			font-size: 16px;
			line-height: 24px; /* 150% */
		}
	}
}
</style>
