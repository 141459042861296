<template>
	<div class="view-container">
		<div v-if="emptyMenus" class="full-empty">
			<Empty text="暂无配置菜单内容" />
		</div>
		<template v-else>
			<div class="top-menu-tabs">
				<div class="top-menu-tabs-w100" :style="width100NodeStyle"></div>
				<div v-if="composeMenus.length" class="top-menu-swiper">
					<swiper :options="swiperOption" ref="mySwiper" style="height: 100%">
						<swiper-slide v-for="(item, index) in composeMenus" :key="index">
							<div class="menu-tab-wrapper">
								<div
									v-for="(v2, i2) in item"
									:key="i2"
									class="menu-tab-item"
									:class="{
										'menu-tab-item--active': activeMenuId == v2.id
									}"
									@click="switchMenu(v2)"
								>
									<div class="menu-tab-item-label">{{ v2.name }}</div>
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>
				<div v-if="composeMenus.length > 1" class="menu-stepper-wrapper">
					<i
						v-if="activeSwiperIndex != 0"
						class="iconfont-stepper iconfont icon-flip-left"
						@click="prevSwiper"
					></i>
					<i
						v-if="activeSwiperIndex != composeMenus.length - 1"
						class="iconfont-stepper iconfont icon-flip-right"
						@click="nextSwiper"
					></i>
				</div>
			</div>
			<div class="menu-wrapper">
				<!-- 预览遮罩 禁止跳转 -->
				<div v-if="disabledClick" class="preview-mask"></div>
				<div
					v-for="(menu, menuIndex) in pageMenus"
					:key="menu.id"
					class="menu-item"
					:class="menu.bgSrc && 'menu-item--padding'"
					:data-index="menuIndex"
				>
					<div v-if="menu.bgSrc" class="w100-background" :style="width100NodeStyle">
						<el-image style="width: 100%; height: 100%" :src="menu.bgSrc" fit="cover"></el-image>
					</div>
					<div
						v-for="(module, index) in menu.moduleVOS"
						:key="module.moduleId"
						class="module-wrapper"
						:class="{
							'module-wrapper--w100': w100StyleModule(menu.moduleVOS, module.moduleId, index),
							mb64: mb64StyleModule(menu.moduleVOS, index)
						}"
					>
						<ModuleHeader :label="module.name" class="mb-32" @toMore="goModulePage(module)"> </ModuleHeader>
						<component
							ref="modulesRef"
							v-bind="componentProps(module, menu, index)"
							:is="pickComponent(module)"
							class="module-wrapper-component"
						/>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'home'
};
</script>
<script setup>
import ModuleHeader from '@/views/home/components/ModuleHeader.vue';
import StockQuotes from '@/views/home/components/modules/StockQuotes.vue';
import QuarterlyPerformance from '@/views/home/components/modules/QuarterlyPerformance.vue';
import CustomsCalendar from '@/views/home/components/modules/CustomsCalendar.vue';
import InvestmentActivity from '@/views/home/components/modules/InvestmentActivity.vue';
import Announcement from '@/views/home/components/modules/Announcement.vue';
import News from '@/views/home/components/modules/News.vue';
import QuestionAndAnswer from '@/views/home/components/modules/QuestionAndAnswer.vue';
import ResearchReports from '@/views/home/components/modules/ResearchReports.vue';
import CustomModule from '@/views/home/components/modules/CustomModule.vue';
import { useRouter, isFunction, debounce, throttle, getUuid } from '@/utils/api';
import { onMounted, onBeforeUnmount, ref, nextTick, computed, watch, onActivated, onDeactivated } from 'vue';
import { useStockVO, useCommonData } from '@/hooks';
import IMG_MODULE_BG_ACTIVITY from '@/assets/images/home/module_bg_activity.png';
import IMG_MODULE_BG_QUARTERLY from '@/assets/images/home/module_bg_quarterly.png';
import IMG_MODULE_BG_QUARTERLY_BLUE from '@/assets/images/home/module_bg_quarterly_blue.png';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import { useStore, useRoute } from '@/utils/api';
import { WEBIRM_HOST_LIST } from '@/const/host';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { setCookie, removeCookie } from '@/utils/cookie';

const swiperOption = {
	// 禁用触摸滑动
	allowTouchMove: false
};
const mySwiper = ref();
const activeSwiperIndex = computed(() => {
	if (!mySwiper.value) return 0;
	return mySwiper.value.swiper.activeIndex;
});
const prevSwiper = () => {
	mySwiper.value.swiper.slidePrev();
};
const nextSwiper = () => {
	mySwiper.value.swiper.slideNext();
};
const composeMenus = computed(() => {
	const filterMenus = pageMenus.value
		.filter(menu => menu.moduleVOS?.length && menu.name)
		.map(item => ({ name: item.name, id: item.id }));
	return splitStringsIntoChunksWithPadding(filterMenus || []);
});
const splitStringsIntoChunksWithPadding = stringsArray => {
	if (!stringsArray.length) return [];

	const maxChunkWidth = 1186; // 最大宽度 稍微拉大一点 容错
	const singleCharW = 16; // 假定每个字符的宽度 80/5
	const menuPadding = 32; // 每个菜单的内边距
	const menuMargin = 40; // 每个菜单的间距
	const result = [[]]; // 初始化一个二维数组，第一个子数组用于存放字符串

	let currentChunkWidth = 0; // 当前子数组的宽度

	for (const value of stringsArray) {
		const { name } = value;
		const singleMenuW = name.length * singleCharW + menuPadding; // 计算字符串宽度，加上额外的宽度补偿

		if (currentChunkWidth + singleMenuW <= maxChunkWidth) {
			// 如果当前子数组宽度加上字符串宽度不超过最大宽度，添加到当前子数组
			result[result.length - 1].push({ ...value });
			currentChunkWidth = currentChunkWidth + singleMenuW + menuMargin;
		} else {
			// 否则，创建新的子数组并添加字符串
			result.push([{ ...value }]);
			currentChunkWidth = singleMenuW + menuMargin; // 更新当前子数组的宽度为新添加的元素的宽度
		}
	}
	return result;
};

const { getFullCode } = useStockVO();
watch(
	() => getFullCode.value,
	() => {
		updatePageContent({}, false);
	}
);

const router = useRouter();
const ROUTE_NAME_MAP_BY_MODULE_ID = {
	quote: 'stockDetail', // 股票行情
	investment_activity: 'investmentColumn', // 投关活动
	announcement: 'announCement', // 公告
	report: 'researchList', // 研报
	news: 'news', // 新闻
	question: 'question', //互动问答
	quarterly_performance: 'quarterlyPerformance', // 季度业绩
	custom: 'custom', //自定义模块
	customs_calendar: 'customsCalendar' // 投关日历
};
const goModulePage = ({ type, moduleId, name }) => {
	if (type == 1) {
		router.push({
			name: ROUTE_NAME_MAP_BY_MODULE_ID.custom,
			query: { moduleId, title: name }
		});
		return;
	}
	if (ROUTE_NAME_MAP_BY_MODULE_ID[moduleId]) {
		router.push({
			name: ROUTE_NAME_MAP_BY_MODULE_ID[moduleId],
			query: {
				title: name
			}
		});
	}
};
// 兼容宽度铺满的样式
const w100StyleModule = (vos, moduleId, index) => {
	if (vos.length == 1) return true;
	if (moduleId == 'investment_activity' || moduleId == 'quarterly_performance') return true;
	if (vos.length % 2 != 0 && vos.length == index + 1) return true;
	return false;
};
//兼容模块上下间距
const mb64StyleModule = (vos, index) => {
	if (vos.length <= 2) return false;
	if (vos.length % 2 != 0) {
		if (![vos.length - 1].includes(index)) return true;
	} else {
		if (![vos.length - 1, vos.length - 2].includes(index)) return true;
	}
};

const COMPONENT_MAP_BY_MODULE_ID = {
	quote: StockQuotes, // 股票行情
	investment_activity: InvestmentActivity, // 投关活动
	announcement: Announcement, // 公告
	question: QuestionAndAnswer, // 问答
	report: ResearchReports, // 研报
	news: News, // 新闻
	quarterly_performance: QuarterlyPerformance, // 季度业绩
	customs_calendar: CustomsCalendar // 投关日历
};
const pickComponent = ({ type, moduleId }) => {
	if (type == 1) return CustomModule;
	return COMPONENT_MAP_BY_MODULE_ID[moduleId];
};
const componentProps = ({ type, moduleId }, menu, moduleIdx) => {
	if (type == 1) {
		return {
			moduleId
		};
	}
	if (moduleId == 'quote' || moduleId == 'customs_calendar') {
		return {
			col: calculatePercentage(menu.moduleVOS.length, moduleIdx + 1)
		};
	}
	if (moduleId == 'quarterly_performance') {
		return {
			homeStyle: true
		};
	}
	return {};
};
function calculatePercentage(total, position) {
	if (total === 1) return '100%';
	// 对于偶数，直接返回 50%
	if (total % 2 === 0) {
		return '50%';
	}
	// 对于奇数，判断位置
	else {
		// 在奇数总数的情况下，position 超过阈值时，意味着它在序列中的位置比较靠后，因此输出 100%。
		// 如果没有超过阈值，说明位置比较靠前，输出 50%。
		const threshold = (total + 1) / 2;
		return position > threshold ? '100%' : '50%';
	}
}
const getModuleBgImg = modules => {
	const isBlueTheme = getUpperThemeColor.value == '#3964E5';
	let bgSrc = null;
	let bgMap = {
		quarterly_performance: isBlueTheme ? IMG_MODULE_BG_QUARTERLY_BLUE : IMG_MODULE_BG_QUARTERLY,
		investment_activity: IMG_MODULE_BG_ACTIVITY
	};
	if (modules && modules.length == 1) {
		modules.forEach(module => {
			if (bgMap[module.moduleId]) {
				bgSrc = bgMap[module.moduleId];
			}
		});
	}
	return bgSrc;
};

const { getUpperThemeColor } = useCommonData();

const modulesRef = ref();
const emptyMenus = ref(false);
const pageMenus = ref([]);
const updatePageMenus = menuData => {
	const menus = (menuData || [])
		.map((data, index) => {
			data.id = data.id || getUuid();
			data.sort = data.sort || index + 1;
			data.bgSrc = getModuleBgImg(data.moduleVOS);
			data.moduleVOS = (data.moduleVOS || [])
				.map((module, idx) => {
					module.sort = module.sort || idx + 1;
					return module;
				})
				.sort((moduleA, moduleB) => moduleA.sort - moduleB.sort);
			// .slice(0, 2); // 目前的单个菜单 仅支持最多两个模块的展示
			return data;
		})
		.filter(menu => menu.moduleVOS.length)
		.sort((a, b) => a.sort - b.sort);

	if (menus.length) {
		emptyMenus.value = false;
		pageMenus.value = menus;
		activeMenuId.value = pageMenus.value[0].id;
	} else {
		emptyMenus.value = true;
		pageMenus.value = [];
	}

	const waitExecuteList = [];
	nextTick(() => {
		createObserver();

		modulesRef.value &&
			modulesRef.value.forEach(module => {
				isFunction(module.refreshComponent) && waitExecuteList.push(() => module.refreshComponent());
			});
		executeTasks(waitExecuteList, 5).then(() => {
			console.log('All tasks executed');

			nextTick(() => {
				// 每次模块更新后 触发一下节点宽度计算
				calcFullStyle();
			});
		});
	});
};
async function executeTasks(tasks, maxConcurrent) {
	if (tasks.length === 0) {
		return Promise.resolve();
	}
	let group = tasks.splice(0, maxConcurrent);
	return Promise.all(group.map(task => task())).then(() => {
		return executeTasks(tasks, maxConcurrent);
	});
}

const observerInstance = ref(null);
const createObserver = () => {
	if (observerInstance.value) {
		observerInstance.value.disconnect();
		observerInstance.value = null;
	}

	const menuItems = document.querySelectorAll('.menu-item');
	const activeItems = new Set(); // 存储当前在视口内的元素

	const observerOptions = {
		root: null, // 默认是视口
		rootMargin: '0px',
		threshold: 0.5 // 元素至少有50%进入视口时触发回调
	};
	const observerCallback = (entries, observer) => {
		if (skipObserver.value) return;

		entries.forEach(entry => {
			if (entry.isIntersecting) {
				activeItems.add(entry.target);
			} else {
				activeItems.delete(entry.target);
			}
		});

		// 移除所有元素的 in-view 类
		menuItems.forEach(item => item.classList.remove('in-view'));

		// 仅为最靠前的激活元素添加 in-view 类
		if (activeItems.size > 0) {
			// console.log('observer start.');

			const mostTopItem = [...activeItems].sort((a, b) => {
				return parseInt(a.getAttribute('data-index')) - parseInt(b.getAttribute('data-index'));
			})[0];

			const findTopIndex = mostTopItem.getAttribute('data-index');
			const findTopId = pageMenus.value[findTopIndex].id;

			const twoDimensionalArray = composeMenus.value;
			// 查找 mostTopItemId 在二维数组中的位置
			let foundSwiperIndex = -1;
			for (let i = 0; i < twoDimensionalArray.length; i++) {
				for (let j = 0; j < twoDimensionalArray[i].length; j++) {
					if (twoDimensionalArray[i][j].id === findTopId) {
						foundSwiperIndex = i;
						break;
					}
				}
				if (foundSwiperIndex !== -1) break;
			}

			// 比较 foundIndex 和 activeIndex 并调用相应函数
			if (foundSwiperIndex < activeSwiperIndex.value) {
				prevSwiper();
			} else if (foundSwiperIndex > activeSwiperIndex.value) {
				nextSwiper();
			}

			// 更新激活下标
			activeMenuId.value = findTopId;
			mostTopItem.classList.add('in-view');
		}
	};

	observerInstance.value = new IntersectionObserver(observerCallback, observerOptions);
	menuItems.forEach(item => {
		observerInstance.value.observe(item);
	});
};

const skipObserver = ref(false);
const onScrollStop = () => {
	// 全局的「回到顶部」会触发这个状态变更
	if (skipObserver.value) {
		skipObserver.value = false;
	}
};
// 借助一个简单的防抖来模拟滚动停止的监听
const debouncedOnScrollStop = debounce(onScrollStop, 200);
onMounted(() => {
	window.addEventListener('scroll', debouncedOnScrollStop);
});

const activeMenuId = ref();
const switchMenu = debounce(
	function ({ element, childIndex, id }) {
		//id为菜单id,从pc端postMessage过来的信息没有菜单id，只有element, childIndex
		const navWrapperNode = topMenuNode.value;
		const menuWrapperNode = document.querySelector('.menu-wrapper');
		const menuNodeList = document.querySelectorAll('.menu-item');
		const findMenuIdx = pageMenus.value.findIndex(item =>
			id ? item.id == id : item.moduleVOS.find(item => item.moduleId == element.moduleId)
		);
		const findMenuNode = menuNodeList[findMenuIdx];
		let findModule = null;
		if (!id) {
			findModule = findMenuNode.querySelectorAll('.module-wrapper')[childIndex];
		}
		if (findMenuNode) {
			skipObserver.value = true;
			activeMenuId.value = id ? id : pageMenus.value[findMenuIdx].id;
			const _top = menuWrapperNode.offsetTop + findMenuNode.offsetTop - navWrapperNode.offsetHeight;
			window.scrollTo({
				top: id ? _top : _top + findModule.offsetTop,
				behavior: 'smooth'
			});
		}
	},
	300,
	true
);

const topMenuNode = ref();
// 预览通信
onMounted(() => {
	topMenuNode.value = document.querySelector('.top-menu-tabs');
	window.addEventListener('message', e => {
		// 仅通信webirm的域名
		const includeWebIrm = WEBIRM_HOST_LIST.includes(e.origin);
		if (!includeWebIrm) return;
		if (e.data.updateData) {
			updatePageContent(e.data.updateData, false);
		}

		if (e.data.scrollData) {
			switchMenu(e.data.scrollData);
		}
	});
});

const store = useStore();
const updatePageContent = (content, overwrite = true) => {
	console.log('😪 # updatePageContent # content:', content);
	store.commit('setCommonData', {
		data: content || {},
		overwrite
	});
	if (!content) {
		emptyMenus.value = true;
		return;
	}

	store.dispatch('storeEvent/emit', {
		event: 'banner',
		payload: content.backgroundImage
	});

	updatePageMenus(store.getters.getCommonData.pageMenuVOS);
};
const fetchPageDetail = async (storeId, pageId) => {
	return instance
		.get(enterprise.getPageDetail(storeId, pageId))
		.then(response => {
			return response.data.data;
		})
		.catch(() => {});
};
const fetchColData = async storeId => {
	return instance
		.get(enterprise.getCommonIRStore(storeId))
		.then(response => {
			if (response.data.data) {
				store.commit('setColumnData', response.data.data);
			}
		})
		.catch(() => {});
};
const fetchPageSaveData = async (storeId, pageId) => {
	return instance
		.get(enterprise.getPageSaveContent(storeId, pageId))
		.then(response => {
			return response.data.data;
		})
		.catch(() => {});
};

const route = useRoute();
const disabledClick = ref(false);
const checkErrorValue = value => {
	const targetSet = new Set([undefined, 'undefined', null, 'null']);
	return targetSet.has(value);
};
onMounted(async () => {
	const { storeId, pageId, preview, enableUpload, jmEnvs } = route.query;

	// 预发布判断
	if (jmEnvs) {
		setCookie('release', 'pro_001');
		setCookie('symbol', 'clean');
	} else {
		removeCookie('release');
		removeCookie('symbol');
	}

	if (checkErrorValue(storeId) || checkErrorValue(pageId)) {
		emptyMenus.value = true;
		return;
	}

	let detailData = await fetchPageDetail(storeId, pageId);
	let onlySaveContent;
	if (preview) {
		// 如果是从预览过来 就要获取一次仅保存的信息 覆盖content
		onlySaveContent = await fetchPageSaveData(storeId, pageId);
		// 仅展示 保存的信息
		if (onlySaveContent) {
			// 兼容：如果是保存了但未应用 此时detailData=undefined
			detailData = Object.assign(detailData || {}, onlySaveContent);
		} else {
			// 赋值同步
			detailData = onlySaveContent;
		}
	}

	updatePageContent(detailData);

	// 更新栏目信息
	storeId && fetchColData(storeId);

	if (enableUpload) {
		disabledClick.value = true;
	}
});

// 宽度铺满计算
const width100NodeStyle = ref({
	left: '',
	width: ''
});
const calcFullStyle = throttle(function () {
	if (emptyMenus.value) return;

	let calcStyle = {};
	const appNode = document.getElementById('app');
	const maxWrapperNode = document.querySelector('.layout-main');
	const maxWrapperNodeWidth = maxWrapperNode.offsetWidth;
	const wrapperNode = document.querySelectorAll('.menu-item')[0];
	if (!wrapperNode) return;
	const wrapperNodeWidth = wrapperNode.offsetWidth;

	let calcLeft = (-1 * (appNode.offsetWidth - wrapperNodeWidth)) / 2;
	calcLeft = appNode.offsetWidth < maxWrapperNodeWidth ? 0 : calcLeft;
	let calcWidth = appNode.offsetWidth;
	calcWidth = appNode.offsetWidth < maxWrapperNodeWidth ? maxWrapperNodeWidth : appNode.offsetWidth;

	calcStyle.left = `${calcLeft}px`;
	calcStyle.width = `${calcWidth}px`;
	width100NodeStyle.value = calcStyle;
}, 16.7);
onMounted(() => {
	window.addEventListener('resize', calcFullStyle);
});
onBeforeUnmount(() => {
	window.removeEventListener('resize', calcFullStyle);
});
onActivated(() => {
	window.addEventListener('resize', calcFullStyle);
});
onDeactivated(() => {
	window.removeEventListener('resize', calcFullStyle);
});
</script>

<style lang="less" scoped>
.view-container {
	min-height: 780px;
	display: flex;
	flex-direction: column;

	.full-empty {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.top-menu-tabs {
		height: 62px;
		display: flex;
		align-items: center;
		position: sticky;
		top: 0;
		left: 0;
		z-index: 21;

		.top-menu-tabs-w100 {
			position: absolute;
			top: 0;
			bottom: 0;
			background: #f7f8fa;
		}
		.top-menu-swiper {
			width: 1136px;
			height: 100%;
			position: relative;
		}
		.menu-stepper-wrapper {
			flex: 1;
			width: 0;
			display: flex;
			justify-content: flex-end;
		}
		.iconfont-stepper {
			font-size: 12px;
			position: relative;
			color: #666666;
			cursor: pointer;

			& + .iconfont-stepper {
				margin-left: 12px;
			}
		}
		.menu-tab-wrapper {
			height: 100%;
			display: flex;
			align-items: center;
		}
		.menu-tab-item {
			padding: 0 16px;
			height: 100%;
			display: flex;
			align-items: center;
			position: relative;
			cursor: pointer;

			& + .menu-tab-item {
				margin-left: 40px;
			}
			.menu-tab-item-label {
				color: #4e5969;
				font-family: 'PingFang SC';
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 140%; /* 22.4px */
			}
			&--active {
				.menu-tab-item-label {
					color: #1d2129;
				}
				&::after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					bottom: 1px;
					border-bottom: 1px solid var(--theme-color);
				}
			}
		}
	}

	.menu-wrapper {
		position: relative;

		.preview-mask {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 20;
		}
	}
	.menu-item {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		position: relative;

		&.in-view {
			// 调试用
			// background: rgba(255, 182, 193, 0.6);
		}

		& + .menu-item {
			margin-top: 64px;
		}
		&--padding {
			padding: 64px 0;

			& + .menu-item--padding {
				margin-top: 0;
			}
		}
		&:not(.menu-item--padding) {
			margin-top: 64px;

			&:last-child {
				margin-bottom: 64px;
			}
		}
	}
	.module-wrapper {
		width: calc(50% - 48px);
		display: flex;
		flex-direction: column;
		position: relative;

		&--w100 {
			width: 100%;
		}
		.module-wrapper-component {
			flex: 1;
		}
		/deep/ .module-wrapper-component {
			// 撑起缺省图高度
			& > div:first-of-type {
				height: 100%;
			}
		}
	}
	.mb64 {
		margin-bottom: 64px;
	}
	.w100-background {
		position: absolute;
		top: 0;
		bottom: 0;
	}
}
</style>
