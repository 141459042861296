<template>
	<CLoadingBox :loading="loading" :empty="empty">
		<div class="activity-wrapper">
			<div v-for="(item, index) in activityList" :key="index" class="activity-item">
				<div class="activity-icon">
					<el-image :src="actIconImgList[index]" fit="cover" />
				</div>
				<div class="activity-title mt-20 text-overflow3">{{ item.subject }}</div>
				<div class="activity-other">
					<div class="activity-footer mt-20">
						<div class="activity-date">{{ formatDateFn(item.date) }}</div>
						<div class="activity-more" @click="handleClick(item)">
							<div>查看详情</div>
							<i class="iconfont icon-flip-right"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</CLoadingBox>
</template>

<script setup>
import { ref, watch, defineExpose, computed } from 'vue';
import dayjs from 'dayjs';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import { useCommonData, useDataState } from '@/hooks';
import { useStore, previewFile, useRoute } from '@/utils/api';
import { IRSTORE_T_CLICK } from '@/utils/track';

const formatDateFn = date => {
	return dayjs(date).format('YYYY-MM-DD');
};
const route = useRoute();
const handleClick = item => {
	const { id, url } = item;
	if (!url) return;

	const { pageId, storeId } = route.query;
	IRSTORE_T_CLICK(283, {
		params: {
			点击内容名称: '投关活动',
			点击内容类型: '自定义添加内容',
			内容ID: id,
			企业ID: storeId,
			页面ID: pageId
		}
	});

	var pattern = /comein-files\.oss-cn-hangzhou\.aliyuncs\.com/;
	var reg = pattern.test(url);
	if (reg) return previewFile(url);

	window.open(url, '_blank');
};

const actIconImgList = ref([]);
const store = useStore();
const { getUpperThemeColor } = useCommonData();
watch(
	() => getUpperThemeColor.value,
	color => {
		const themeMap = store.state.storeTheme.upperThemeColorMap;
		const imgList = [
			require(`@/assets/images/home/act_icon_01_${themeMap[color]}.png`),
			require(`@/assets/images/home/act_icon_02_${themeMap[color]}.png`),
			require(`@/assets/images/home/act_icon_03_${themeMap[color]}.png`)
		];
		actIconImgList.value = imgList;
	},
	{ immediate: true }
);

const { getStoreId } = useCommonData();
const { data, loading, empty, setData, setLoading } = useDataState();
const activityList = computed(() => (data.value || []).slice(0, 3));
const fetchList = async () => {
	setLoading(true);
	return instance
		.get(enterprise.getAllActivityList(getStoreId.value), {
			pageNum: 20,
			pageStart: 0
		})
		.then(response => {
			setData(response.data?.data);
			setLoading(false);
		})
		.catch(() => {
			setData([]);
			setLoading(false);
		});
};
const refreshComponent = () => {
	fetchList();
};

defineExpose({
	refreshComponent
});
</script>

<style lang="less" scoped>
.activity {
	&-wrapper {
		display: flex;
		justify-content: space-between;
	}
	&-item {
		width: calc(33.3% - 14px);
		height: 216px;
		background: #fff;
		padding: 24px;
		box-sizing: border-box;
		position: relative;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
	&-icon {
		position: absolute;
		top: 24px;
		right: 24px;
		width: 72px;
		height: 72px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&-title {
		color: #1d2129;
		font-family: 'PingFang SC';
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 140%; /* 22.4px */
		width: 239px;
	}
	&-other {
		.activity-desc {
			color: #86909c;
			font-family: 'PingFang SC';
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 140%; /* 16.8px */
			width: 320px;
		}
	}
	&-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&-date {
		color: #4e5969;
		font-family: 'PingFang SC';
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px; /* 114.286% */
	}
	&-more {
		padding: 0 12px;
		height: 32px;
		display: flex;
		align-items: center;
		background: var(--theme-btn-lv2-color);
		cursor: pointer;
		color: #4e5969;
		font-family: 'PingFang SC';
		font-size: 14px;
		font-style: normal;
		font-weight: 400;

		.iconfont {
			font-size: 16px;
		}
	}
}
</style>
