const storeKeepAlive = {
	namespaced: true,
	state: () => ({
		cachePages: [],
		keepAliveRules: {
			news: ['newsDetail'],
			researchList: ['researchDetail']
		}
	}),
	getters: {
		getCachePages: state => state.cachePages
	},
	mutations: {
		addInclude(state, pageName) {
			state.cachePages.push(pageName);
		},
		removeInclude(state, pageName) {
			const index = state.cachePages.indexOf(pageName);
			if (index !== -1) {
				state.cachePages.splice(index, 1);
			}
		}
	}
};

export default storeKeepAlive;
