<template>
	<CLoadingBox :loading="loading" :empty="empty">
		<div class="quarter-wrapper">
			<div class="quarter-flex-1 quarter-overview">
				<div class="color-fill"></div>
				<div class="color-mixin"></div>
				<div class="overview-content">
					<div class="quarter-title">
						{{ OrganizationName }}（{{ code }}）{{ quarterDetails.announcementTitle }}
					</div>
					<div class="quarter-info mt-48">
						<div class="quarter-info-item" v-for="(value, key) in quarterDetails.coreIndicators" :key="key">
							<div class="quarter-info-title">{{ key | changeCore }}</div>
							<div class="quarter-info-data">{{ handleCoreValue(value, key) }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="quarter-flex-1 quarter-performance-entry">
				<template v-if="filterEnterList.length">
					<div
						v-for="(item, index) in filterEnterList"
						:key="index"
						:class="['quarter-performance-item', homeStyle && 'quarter-performance-item--white']"
						@click="gotoPerformanceEntry(item)"
					>
						<div class="quarter-performance-icon">
							<el-image :src="performanceIconList[index]" style="width: 100%; height: 100%" fit="cover" />
						</div>
						<div class="quarter-performance-label">{{ item.label }}</div>
					</div>
				</template>
				<div v-else class="quarter-performance-empty" :class="homeStyle && 'quarter-performance-empty--white'">
					<Empty />
				</div>
			</div>
			<div class="quarter-flex-2 quarter-footer mt-48" v-if="quarterDetails.summarize">
				<div class="quarter-footer-banner">
					<el-image :src="summaryImg" fit="cover" class="summary-img"></el-image>
				</div>
				<div class="quarter-footer-content" v-html="quarterDetails.summarize"></div>
			</div>
		</div>
	</CLoadingBox>
</template>

<script>
export const CORE_OPTION = {
	roe: 'ROE',
	roa: 'ROA',
	nAssetPs: '每股净资产',
	nCInCashPs: '每股现金流',
	grossMargin: '毛利率',
	eps: '每股收益',
	assetLiabRatio: '负债率',
	reserPs: '每股公积金',
	rePs: '每股未分配',
	revPs: '每股营收'
};
export default {
	data() {
		return {};
	},
	filters: {
		changeCore(val) {
			return CORE_OPTION[val];
		}
	}
};
</script>

<script setup>
import { ref, watch, computed, defineExpose } from 'vue';
import { useStore } from '@/utils/api';
import { previewAnnounce } from '@/utils/fileDetail';
import { useCommonData, useDataState } from '@/hooks';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import { IRSTORE_T_CLICK } from '@/utils/track';

const props = defineProps({
	performanceYear: {
		type: String,
		default: ''
	},
	quarter: {
		type: Number,
		default: 1
	},
	homeStyle: {
		type: Boolean,
		default: false
	}
});

const { getStoreId, getOrganizationName, getStockInfoVOS } = useCommonData();

const quarterListDetails = ref({});
const OrganizationName = ref('');
const code = ref('');
setTimeout(() => {
	OrganizationName.value = getOrganizationName.value;
	code.value = getStockInfoVOS.value?.[0].code;
}, 500);
let activeYear = ref();
let activeQuarter = ref();
watch(
	[() => props.performanceYear, () => props.quarter],
	([year, quarter]) => {
		activeYear.value = year;
		activeQuarter.value = quarter;
	},
	{
		immediate: true
	}
);
const quarterDetails = computed(() => {
	let obj = {};
	const keys = Object.keys(CORE_OPTION).map(key => key);
	let target = quarterListDetails.value[activeYear.value]?.find(item => item.quarter == activeQuarter.value) || {};
	keys.forEach(key => {
		if (Object.prototype.hasOwnProperty.call(target.coreIndicators || {}, key)) {
			obj[key] = target?.coreIndicators?.[key];
		}
	});
	target.coreIndicators = obj;
	return target;
});
const emits = defineEmits(['init']);

const { loading, empty, setLoading } = useDataState();
const fetchQuarterPerformance = getStoreId => {
	setLoading(true);
	return instance
		.get(enterprise.getQuarterPerformance(getStoreId))
		.then(res => {
			quarterListDetails.value = res?.data?.data;
			let target;
			Object.values(res?.data?.data).forEach(item => {
				if (item.find(k => k.isTop)) target = item.find(k => k.isTop);
			});
			activeYear.value = target ? target.performanceYear : Object.keys(res?.data?.data).reverse()?.[0];
			activeQuarter.value = target ? target.quarter : res?.data?.data[activeYear.value]?.[0]?.quarter;

			setLoading(false);
			return res.data.data;
		})
		.catch(() => {
			setLoading(false);
			return {};
		});
};
const summaryImg = ref('');
const performanceIconList = ref([]);
const store = useStore();
const { getUpperThemeColor } = useCommonData();
watch(
	() => getUpperThemeColor.value,
	color => {
		if (color) {
			const themeMap = store.state.storeTheme.upperThemeColorMap;
			const imgList = [
				require(`@/assets/images/home/performance_01_${themeMap[color]}.png`),
				require(`@/assets/images/home/performance_02_${themeMap[color]}.png`),
				require(`@/assets/images/home/performance_03_${themeMap[color]}.png`),
				require(`@/assets/images/home/performance_04_${themeMap[color]}.png`)
			];

			performanceIconList.value = imgList;

			summaryImg.value = require(`@/assets/images/home/quarterly_summary_${themeMap[color]}.png`);
		}
	},
	{ immediate: true }
);

const filterEnterList = computed(() => {
	const entryList = [
		{
			label: '业绩公告',
			key: 'announcementLink'
		},
		{
			label: '业绩摘要',
			key: 'roundupLink'
		},
		{
			label: '业绩演示',
			key: 'showLink'
		},
		{
			label: '业绩路演',
			key: 'roadshowLink'
		}
	];
	return entryList.filter(entry => !!quarterDetails.value[entry.key]);
});

const executePerfTrack = (id, name) => {
	const { id: pageId, storeId } = store.getters.getCommonData;
	IRSTORE_T_CLICK(283, {
		params: {
			点击内容名称: `季度业绩/${name}`,
			点击内容类型: '自定义添加内容',
			内容ID: id,
			企业ID: storeId,
			页面ID: pageId
		}
	});
};
const gotoPerformanceEntry = entry => {
	let label = entry.label;
	const { announcementLink, roundupLink, showLink, roadshowLink, id } = quarterDetails.value;
	if (label == '业绩公告' && announcementLink) {
		executePerfTrack(id, '业绩公告');
		previewAnnounce({ comeinLink: announcementLink, id });
	} else if (label == '业绩摘要' && roundupLink) {
		executePerfTrack(id, '业绩摘要');
		previewAnnounce({ comeinLink: roundupLink, id });
	} else if (label == '业绩演示' && showLink) {
		executePerfTrack(id, '业绩演示');
		window.open(showLink, '_blank');
	} else if (label == '业绩路演' && roadshowLink) {
		executePerfTrack(id, '业绩路演');
		window.open(roadshowLink, '_blank');
	}
};

const refreshComponent = async () => {
	if (!getStoreId.value) return;
	const res = await fetchQuarterPerformance(getStoreId.value);
	emits('init', res);
};
defineExpose({
	refreshComponent
});

const handleCoreValue = (val, key) => {
	if (['roe', 'roa', 'grossMargin', 'assetLiabRatio'].includes(key)) {
		return val.toFixed(2) + '%';
	} else {
		return val.toFixed(2);
	}
};
</script>

<style lang="less" scoped>
.quarter {
	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		min-height: 254px;
	}
	&-flex-1 {
		flex: 1;

		& + .quarter-flex-1 {
			margin-left: 96px;
		}
	}
	&-flex-2 {
		width: 100%;
	}
	&-overview {
		position: relative;
		min-height: 254px;
		.color-fill {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: var(--theme-color);
			opacity: 0.9;
		}
		.color-mixin {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: url('~@/assets/images/home/quarter_overview_bg.png'),
				lightgray -49px -0.189px / 108.877% 102.118% no-repeat;
			mix-blend-mode: color-burn;
		}
		.overview-content {
			padding: 0 34px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;
			height: 100%;
		}
	}
	&-title {
		color: #fff;
		font-family: 'PingFang SC';
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%; /* 33.6px */
		text-align: center;
	}
	&-info {
		display: flex;

		&-item {
			flex: 1;

			& + .quarter-info-item {
				margin-left: 72px;
			}
		}
		&-title {
			color: #fff;
			font-family: 'PingFang SC';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 114.286% */
		}
		&-data {
			margin-top: 4px;
			color: #fff;
			font-family: Barlow;
			font-size: 32px;
			font-style: normal;
			font-weight: 500;
			line-height: 140%; /* 44.8px */
		}
	}
	&-performance {
		&-entry {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: space-between;
		}
		&-empty {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			&.quarter-performance-empty--white {
				background: #ffffff;
			}
		}
		&-item {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			background: linear-gradient(78.14deg, rgba(248, 250, 255, 0.4) 0%, #eef0f7 100%);
			cursor: pointer;

			&--white {
				background: #fff;
			}

			/* 一个 */
			&:first-child:nth-last-child(1) {
				width: 100%;
				height: 100%;
			}
			/* 两个 */
			&:first-child:nth-last-child(2),
			&:first-child:nth-last-child(2) ~ & {
				width: 100%;
				height: calc(50% - 8px);
			}
			/* 三个 */
			&:first-child:nth-last-child(3),
			&:first-child:nth-last-child(3) ~ & {
				width: calc(50% - 8px);
				height: calc(50% - 8px);
				&:nth-child(3) {
					width: 100%;
				}
			}
			/* 四个 */
			&:first-child:nth-last-child(4),
			&:first-child:nth-last-child(4) ~ & {
				width: calc(50% - 8px);
				height: calc(50% - 8px);
			}
		}
		&-icon {
			width: 64px;
			height: 64px;
		}
		&-label {
			margin-left: 4px;
			color: #1d2129;
			text-align: center;
			font-family: 'PingFang SC';
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 100% */
		}
	}

	&-footer {
		padding: 32px 24px;
		background: #ffffff;
		display: flex;
	}
	&-footer-banner {
		width: 214px;
		// height: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		flex-shrink: 0;
		.summary-img {
		}
		p {
			color: #fff;
			font-family: 'PingFang SC';
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 140%; /* 33.6px */
			position: relative;
		}
	}
	&-footer-content {
		margin-left: 24px;
		flex: 1;
		/deep/ ol li {
			list-style: auto;
			list-style-position: inside;
		}
		/deep/ ul li {
			list-style: inside;
		}
	}
}
</style>
