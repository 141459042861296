import { useStore } from '@/utils/api';
import { APP_VERSION_NUMBER } from '@/const/host';

// 版号缓存控制
export const useVersion = () => {
	const store = useStore();
	const checkSameVersion = () => {
		const nowVersion = APP_VERSION_NUMBER;
		const getVersion = store.getters.getAppVersion;
		return getVersion == nowVersion;
	};
	const resetAppCache = () => {
		store.commit('resetVersion');
		store.commit('setCommonData', {
			data: {}
		});
	};

	return {
		checkSameVersion,
		resetAppCache
	};
};
