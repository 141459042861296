<template>
	<svg v-on="$listeners" class="svg-icon" aria-hidden="true" :style="{ color, '--svg-light-color': bgColor }">
		<use :xlink:href="'#' + iconId" :fill="color" />
	</svg>
</template>

<script setup>
import { computed, defineProps, ref, watch } from 'vue';
import to from 'await-to-js';
import { useCommonData } from '@/hooks';

const props = defineProps({
	// Use `name` or `isFile` + `fileUrl`

	name: {
		type: String,
		default: ''
	},
	/** 是否应用文件类型的icon */
	isFile: {
		type: Boolean,
		default: false
	},
	fileUrl: {
		type: String,
		default: ''
	}
});

const iconId = ref('');
const color = ref('');
const bgColor = ref('');
// TODO: 优化, 动态计算
const BgColorMap = {
	'#3964E5': '#DBE2FF',
	'#F23D3D': '#FFDBDB',
	'#F26A3D': '#FFD9C2',
	'#8539E6': '#EEDBFF'
};
const { getUpperThemeColor } = useCommonData();

watch(
	() => getUpperThemeColor.value,
	cor => {
		color.value = cor;
		bgColor.value = BgColorMap[cor];
	},
	{ immediate: true }
);

const getIcon = async iconName => {
	if (!iconName) {
		return;
	}
	const [err, svgSprite] = await to(
		new Promise((resolve, reject) => {
			import(`@/assets/icons/${iconName}.svg`)
				.then(svg => {
					resolve(svg);
				})
				.catch(err => {
					reject(err);
				});
		})
	);
	if (err) {
		console.log('🚀 ~ getIcon ~ err:', err);
		return;
	}
	iconId.value = svgSprite?.default?.id;
};

const affixMap = {
	docx: 'doc',
	doc: 'doc',
	xls: 'xls',
	xlsx: 'xls',
	pdf: 'pdf',
	ppt: 'ppt',
	pptx: 'ppt'
};
const responseFileName = url => {
	if (!url) return 'non';
	const index = url.lastIndexOf('.');
	const fileAffix = index === -1 ? '' : url.substring(index + 1).toLowerCase();
	return affixMap[fileAffix] || 'non';
};
const getIconName = computed(() => {
	if (!props.isFile) return props.name;
	return `file_${responseFileName(props.fileUrl)}`;
});
watch(
	() => getIconName.value,
	val => {
		getIcon(val);
	},
	{ immediate: true }
);
</script>

<style scoped>
.svg-icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
</style>
