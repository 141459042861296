import { computed } from 'vue';
import { useStore } from '@/utils/api';

export const useCommonData = () => {
	const store = useStore();
	const getStoreId = computed(() => store.getters.getCommonData?.storeId || '');
	const getOrganizationName = computed(() => store.getters.getCommonData?.organizationName);

	// 主题色统一转大写
	const getUpperThemeColor = computed(() => {
		let themeColor = store.getters.getCommonData?.themeColorsHex || '#3964e5';
		themeColor = themeColor.toLocaleUpperCase();
		return themeColor;
	});
	const getBackgroundImage = computed(() => store.getters.getCommonData?.backgroundImage);
	const getImagePosition = computed(() => store.getters.getCommonData?.imagePosition);
	const getFeedbackEntry = computed(() => store.getters.getCommonData?.leaveWordSwitch);

	const getColumnData = computed(() => store.getters.getCommonData?.innerColumnData || {});
	const getStockInfoVOS = computed(() => store.getters.getCommonData.stockInfoVOS);
	return {
		getStoreId,
		getOrganizationName,
		getUpperThemeColor,
		getBackgroundImage,
		getImagePosition,
		getColumnData,
		getStockInfoVOS,
		getFeedbackEntry
	};
};
