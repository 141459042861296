import { ref } from 'vue';
import { isFunction, debounce } from '@/utils/api';

export const usePagination = (options = {}) => {
	const { defaultPage = 1, bindChange } = options;

	const currentPage = ref(defaultPage);
	const pageSizes = ref([20, 30, 40, 50]);
	const pageSize = ref(20);
	const pageTotal = ref(0);
	const pageLayout = ref('total, sizes, prev, pager, next, jumper');

	const safeChange = debounce(function () {
		isFunction(bindChange) && bindChange();
	}, 100);
	const handleSizeChange = val => {
		pageSize.value = val;
		safeChange();
	};
	const handleCurrentChange = val => {
		currentPage.value = val;
		safeChange();
	};
	const initPagination = () => {
		currentPage.value = 1;
		pageSize.value = 20;
	};
	const updatePaginationTotal = total => {
		pageTotal.value = total;
	};

	return {
		currentPage,
		pageSizes,
		pageSize,
		pageTotal,
		pageLayout,
		handleSizeChange,
		handleCurrentChange,
		initPagination,
		updatePaginationTotal
	};
};
