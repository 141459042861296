<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from '@/utils/api';
import { useVersion } from '@/hooks';

const store = useStore();
const { checkSameVersion, resetAppCache } = useVersion();
onMounted(() => {
	const sameVersion = checkSameVersion();
	if (!sameVersion) {
		// 如果版号不一致 说明需要手动重刷缓存
		resetAppCache();
	} else {
		// 手动调用一次初始化 避免受到页面刷新丢失 store 的影响
		store.commit('setCommonData', {
			data: {},
			overwrite: false
		});
	}
});
</script>
