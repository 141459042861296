import { isBuild } from '@/const/host';

let comeinUrl;
let fileViewerUrl;
let mobileUrl;

// 环境区分
if (isBuild) {
	mobileUrl = 'https://mobile.comein.cn'
	comeinUrl = 'https://server.comein.cn/comein';
	fileViewerUrl = 'https://file-view.comein.cn/onlinePreview?url=';
} else {
	mobileUrl = 'https://testmobile.comein.cn'
	comeinUrl = 'https://testserver.comein.cn/comein';
	fileViewerUrl = 'https://file-view-test.comein.cn/onlinePreview?url=';
}

export const MOBILE_URL = mobileUrl;
export const COMEIN_URL = comeinUrl;
export const FILE_VIEWER_URL = fileViewerUrl;
