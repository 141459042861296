<script>
import { mapState } from 'vuex';

export default {
	props: {
		detail: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		...mapState({
			bilingual: state => state.conferenceDetailBilingual || 'zh'
		})
	},
	render() {
		const composeIrstoreRows = () => {
			const { customsType, announcementVO } = this.detail;
			const rows = [];

			if (customsType === 2) {
				rows.push({
					label: '公告类型',
					value: announcementVO.annoClassif
				});
			}

			return rows;
		};
		const composeRowContent = () => {
			const { type, calendarVO, customsType, investmentActivityType } = this.detail;
			const { isOwn, innerPermission, calendarName } = calendarVO;

			// 如他人日程查看权限为「游客」，只展示一个日历
			const isSubscribe = !isOwn;
			if (isSubscribe && innerPermission === 2) {
				return [
					{
						label: '日历',
						keyname: 'calendarName',
						value: calendarName
					}
				];
			}

			let scheduleRows = [];
			if (type === 1) {
				scheduleRows.push({
					label: '日程描述',
					value: this.detail['scheduleDesc']
				});
			}

			let irstoreRows = [];
			if (type === 2) {
				irstoreRows = composeIrstoreRows();
			}

			const typeLabelMap = {
				0: '沟通活动',
				1: '日程',
				2: '投关日历'
			};
			const typeForIR = {
				1: '路演活动',
				2: '调研活动',
				3: '调研纪要'
			};
			let otherRows = [];
			if (type === 2) {
				if (customsType === 1) {
					otherRows.push({
						label: '类型',
						value: typeForIR[investmentActivityType]
					});
				}
			} else {
				otherRows = [
					{
						label: '类型',
						value: typeLabelMap[type]
					}
				];
			}

			let defaultRowMap = [
				{
					label: '日历',
					keyname: 'calendarName'
				}
			];
			defaultRowMap = defaultRowMap.map(item => {
				const _item = { ...item };
				if (_item.keyname) {
					_item.value = this.detail[_item.keyname];
				}
				return _item;
			});

			return [...irstoreRows, ...otherRows, ...defaultRowMap, ...scheduleRows];
		};
		const rowList = composeRowContent();

		return (
			<div class="c-schedule-wrapper">
				{rowList.map(row => {
					return (
						<div class="c-schedule-row">
							<div class="c-schedule-label">{row.label}</div>
							<div class="c-schedule-value" onClick={() => row.onClick && row.onClick()}>
								<span
									style={{ color: row.color, cursor: row.onClick ? 'pointer' : 'auto' }}
									class={{ 'c-schedule-span--underline': row.onClick }}
								>
									{row.value || '-'}
								</span>
							</div>
						</div>
					);
				})}
			</div>
		);
	}
};
</script>

<style lang="less" scoped>
.c-schedule-wrapper {
	.c-schedule-row {
		display: flex;

		& + .c-schedule-row {
			margin-top: 4px;
		}
		.c-schedule-label {
			width: 70px;
			color: var(--secondary-info-color);
			font-family: 'PingFang SC';
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
		.c-schedule-value {
			flex: 1;
			width: 0;
			display: flex;
			align-items: center;

			span {
				color: var(--emphasis-color);
				font-family: 'PingFang SC';
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
				cursor: pointer;
				word-break: break-all;
			}
			.c-schedule-span--underline {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
</style>
