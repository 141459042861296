<template>
	<div class="calendar-view-box">
		<div class="calendar-thead-wrapper">
			<table class="calendar-view-table">
				<thead class="calendar-view-table--header">
					<th class="fst-cell">
						<div class="calendar-view-th"></div>
					</th>
					<!-- 占位 -->
					<th v-show="!renderTableCols.length">
						<div class="calendar-view-th"></div>
					</th>
					<th v-for="(item, index) in renderTableCols" :key="index">
						<div class="calendar-view-th">
							<template v-if="type == 'week'">
								<div>{{ item.label }}</div>
								<div class="calendar-view-date">{{ formatWeekDate(item.date) }}</div>
							</template>
							<template v-else-if="type == 'day'">
								<slot name="theadCell" :date="item.date" :data="{ ...item }"></slot>
							</template>
						</div>
					</th>
				</thead>
			</table>
		</div>
		<div class="calendar-tbody-wrapper calendar-table-wrapper--scroll">
			<el-scrollbar>
				<table class="calendar-view-table">
					<!-- 不加上这个 thead 会对不齐 -->
					<thead>
						<th class="fst-cell">
							<div class="calendar-view-th"></div>
						</th>
					</thead>
					<tbody class="calendar-view-table--body">
						<div class="calendar-hour-hr" :style="calcNowHrStyle">
							<div class="calendar-hour-hr-date">{{ currMinuteValue }}</div>
						</div>
						<tr v-for="(hour, hIdx) in dayHours" :key="hIdx">
							<td class="fst-cell fst-cell--td">
								<div v-show="shouldShowHourCell(hour)" class="calendar-view-td">
									{{ hIdx == 0 ? '全天' : hour }}
								</div>
							</td>
							<!-- 占位 -->
							<td v-show="!renderTableCols.length">
								<div class="calendar-view-td"></div>
							</td>
							<td v-for="(item, index) in renderTableCols" :key="index">
								<div
									class="calendar-view-td calendar-view-td--hot"
									@click="
										handleCell({
											date: calcHoursRangeSchedule(item.date, hour),
											data: { day: formatDay(item.date), calendarId: item.id }
										})
									"
								>
									<slot
										name="dateCell"
										:date="calcHoursRangeSchedule(item.date, hour)"
										:data="{ day: formatDay(item.date), calendarId: item.id }"
									></slot>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</el-scrollbar>
		</div>
	</div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, defineExpose, onMounted, onActivated, inject } from 'vue';
import moment from 'moment';

const props = defineProps({
	value: {
		type: Date
	},
	/**
	 * 视图类型 "week" | "day"
	 */
	type: {
		type: String,
		default: 'week'
	}
});
const emits = defineEmits(['update:value', 'cell-click']);
const innerValue = computed({
	get: () => {
		if (!props.value) return new Date();
		return props.value;
	},
	set: val => {
		emits('update:value', val);
	}
});

const WEEK_DAYS = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
const getWeekDates = date => {
	let weekStart = moment(date).startOf('week');
	let dates = [];
	for (let i = 0; i < 7; i++) {
		dates.push(weekStart.clone().add(i, 'days'));
	}
	return dates;
};
const curWeekDays = computed(() => {
	const calcWeek = getWeekDates(innerValue.value);
	return WEEK_DAYS.map((week, weekIndex) => {
		return {
			label: week,
			date: calcWeek[weekIndex]
		};
	});
});

const { calendarData } = inject('calendarContext', {});
const getterCalendarSelection = computed(() => calendarData.value);
const curDays = computed(() => {
	return (getterCalendarSelection.value || []).map(item => {
		return {
			...item,
			date: moment(innerValue.value).startOf('day')
		};
	});
});

const renderTableCols = computed(() => {
	return props.type === 'week' ? curWeekDays.value : curDays.value;
});
const handleCell = option => {
	emits('cell-click', option);
};

const getHoursOfTheDay = () => {
	let hours = [];
	for (let i = 0; i < 24; i++) {
		let hour = i < 10 ? '0' + i : i;
		hours.push(hour + ':00');
	}
	return hours;
};
const dayHours = getHoursOfTheDay();
const formatWeekDate = date => {
	return moment(date).format('DD');
};
const formatDay = date => {
	return moment(date).format('YYYY-MM-DD');
};
const calcHoursRangeSchedule = (date, hour) => {
	let splitHour = hour.split(':');
	if (splitHour.length <= 1) {
		splitHour = ['00', '00'];
	}
	const calcComplateDate = moment(date).set({
		hour: splitHour[0],
		minute: splitHour[1],
		second: 0
	});
	const res = moment(calcComplateDate).format('YYYY/MM/DD HH:mm');
	return new Date(res);
};

// 时刻线接近整点单元格的时候 需要暂时隐藏该单元格的时间展示 范围大致是前后 10 分钟（参考飞书）
const shouldShowHourCell = date => {
	if (!date) return true;
	const momentA = moment(date, 'HH:mm');
	const momentB = moment(currMinuteValue.value, 'HH:mm');
	// 当前时间大于 23:00 时, 固定隐藏单元格的时间展示, 因为向下的高度不足
	if (currMinuteValue.value && momentA.format('HH:mm') === '23:00' && momentB.format('HH:mm') > '23:00') return false;

	// 计算A和B的时间差（以分钟为单位）
	const diff = Math.abs(momentA.diff(momentB, 'minutes'));
	// B如果在A前后10分钟范围内 就隐藏该单元格
	const includeDiff = diff <= 10;
	return !includeDiff;
};
// 时刻线计算
const currMinuteValue = ref('');
const calcNowHrStyle = computed(() => {
	let styleInfo = {};
	if (!currMinuteValue.value || JSON.stringify(minuteHeightData) === '{}') {
		styleInfo.top = `${START_HEIGHT}px`;
	} else if (currMinuteValue.value > '23:00') {
		styleInfo.top = `${END_HEIGHT}px`;
	} else {
		styleInfo.top = `${minuteHeightData[currMinuteValue.value]}px`;
	}
	return styleInfo;
});

let minuteHeightData = {};
// 定义起点（00:00）和终点（23:00）的高度值
const START_HEIGHT = 9;
const END_HEIGHT = 1407.2;
const computeHourDifference = () => {
	const hourNodes = document.querySelectorAll('.fst-cell--td');
	if (!hourNodes.length) return;

	// 定义计算高度的函数
	function calculateHeight(startTime, currentTime) {
		// 新计算公式：根据起点终点高度差计算高度
		const totalMinutesInADay = 23 * 60; // "00:00"至"23:00"范围内的总分钟数
		const minutesSinceStart = currentTime.diff(startTime, 'minutes');
		const minutesInRange = Math.min(totalMinutesInADay, minutesSinceStart); // 保证分钟数在指定范围内

		// 使用提供的终点高度值和范围计算当前时间的高度
		const heightDifference = END_HEIGHT - START_HEIGHT;
		const heightAtCurrentTime = START_HEIGHT + (minutesInRange / totalMinutesInADay) * heightDifference;

		return heightAtCurrentTime;
	}

	// 定义生成时间对象的函数
	function generateTimeObject(timeRange) {
		const result = {};
		const startTime = moment(timeRange[0], 'HH:mm');

		for (let i = 0; i < timeRange.length - 1; i++) {
			const start = moment(timeRange[i], 'HH:mm');
			const end = moment(timeRange[i + 1], 'HH:mm');

			while (start < end) {
				const timeString = start.format('HH:mm');
				result[timeString] = calculateHeight(startTime, start);
				start.add(1, 'minutes');
			}
		}

		// 添加最后一个时间点
		const lastTimeString = timeRange[timeRange.length - 1];
		const lastTime = moment(lastTimeString, 'HH:mm');

		// 将最后一个时间点增加1分钟
		lastTime.add(1, 'minutes');
		let currentTime = moment(timeRange[timeRange.length - 1], 'HH:mm');
		while (currentTime < lastTime) {
			const addedTimeString = currentTime.format('HH:mm');
			result[addedTimeString] = calculateHeight(startTime, currentTime);
			currentTime.add(1, 'minutes');
		}

		return result;
	}

	// 将时间范围转换为分钟高度数据
	// [00:00, 01:00] >>> [00:00,00:01,00:02,...,01:00]
	minuteHeightData = generateTimeObject(dayHours);
};
const updateHourMinuteValue = () => {
	const nowHourMinute = moment().format('HH:mm');
	currMinuteValue.value = nowHourMinute;
};
onMounted(() => {
	computeHourDifference();
	updateHourMinuteValue();
});
onActivated(() => {
	updateHourMinuteValue();
});

const valueMap = {
	day: 'days',
	week: 'weeks'
};
const selectDate = type => {
	const nowViewDate = moment(innerValue.value);
	let selectDateValue;

	if (type === 'prev') {
		selectDateValue = nowViewDate.subtract(1, valueMap[props.type]);
	} else if (type === 'next') {
		selectDateValue = nowViewDate.add(1, valueMap[props.type]);
	} else {
		selectDateValue = moment();
	}

	innerValue.value = moment(selectDateValue).toDate();
};
defineExpose({
	selectDate
});
</script>

<style lang="less" scoped>
.calendar-view-box {
	height: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid var(--border-lighter-color);
	border-radius: 2px;
	overflow: hidden;

	.calendar-thead-wrapper {
		position: relative;
		z-index: 2;
		box-shadow: 0 10px 15px rgba(242, 243, 245, 0.25), 0 -10px 15px rgba(255, 255, 255, 0.25);
	}
	.calendar-tbody-wrapper {
		flex: 1;
		height: 0;
	}
}
.calendar-view-table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;

	&--header {
		background: #fff;

		th {
			text-align: left;
			border-right: 1px solid var(--border-lighter-color);

			&:last-child {
				border-right: none;
			}
		}
		.calendar-view-th {
			padding: 12px 8px;
			height: 66px;
			color: var(--secondary-info-color);
			font-family: 'PingFang SC';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px; /* 128.571% */
		}
		.calendar-view-date {
			font-family: Barlow;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 150% */
		}
	}
	&--body {
		background: rgba(57, 100, 229, 0.03);
		position: relative;

		.calendar-hour-hr {
			position: absolute;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;

			&::after {
				content: '';
				display: block;
				flex: 1;
				margin-left: 11px;
				height: 2px;
				background: var(--theme-color);
				transform: scaleY(0.5);
			}
			.calendar-hour-hr-date {
				padding-left: 8px;
				color: var(--theme-color);
				font-family: 'PingFang SC';
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 16px; /* 133.333% */
			}
		}

		tr td {
			text-align: left;
			border-top: 1px solid var(--border-lighter-color);
			border-right: 1px solid var(--border-lighter-color);

			&:last-child {
				border-right: none;
			}
		}
		.calendar-view-td {
			padding: 8px;
			height: 60px;
			// min-height: 60px;
			color: var(--secondary-info-color);
			font-family: 'PingFang SC';
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
			display: flex;
			flex-direction: column;

			&--hot {
				cursor: pointer;
			}
		}
	}
	.fst-cell {
		width: 50px;
	}
}
</style>
