const DEFAULT_THEME_MODE = '#3964e5';

function renameKeysToUpperCase(obj) {
	let newObj = {};
	for (let key in obj) {
		newObj[key.toLocaleUpperCase()] = obj[key];
	}
	return newObj;
}

const storeTheme = {
	namespaced: true,
	state: () => ({
		predefineColors: ['#3964e5', '#F23D3D', '#f26a3d', '#8539e6'],
		upperThemeColorMap: renameKeysToUpperCase({
			'#3964e5': 'blue',
			'#F23D3D': 'red',
			'#f26a3d': 'orange',
			'#8539e6': 'purple'
		})
	}),
	mutations: {
		bindAppThemeStyle(state, data) {
			let safeColor;
			const upperColor = (data || DEFAULT_THEME_MODE).toLocaleUpperCase();
			// 兜底，检查传入的色值是否为记录好的主题规则
			const includeTheme = state.predefineColors.map(c => c.toLocaleUpperCase()).includes(upperColor);
			if (includeTheme) {
				safeColor = upperColor;
			} else {
				safeColor = DEFAULT_THEME_MODE.toLocaleUpperCase();
			}
			const appNode = document.querySelector('body');
			appNode.setAttribute('theme-mode', `theme-${safeColor}`);
		}
	}
};

export default storeTheme;
