<template>
	<el-dialog
		:visible.sync="innerVisible"
		:title="config.title"
		custom-class="c-custom-dialog"
		:before-close="handleClose"
		:close-on-click-modal="false"
	>
		<el-image
			src="https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_1.png"
			fit="cover"
			class="c-custom-mask-img"
		></el-image>
		<p class="tip-content">
			{{ config.leaveWordTips }}
		</p>
		<el-form ref="formRef" :model="innerFormData" :rules="formRules" label-width="100px" label-position="left">
			<el-form-item label="留言内容" prop="question">
				<el-input
					type="textarea"
					v-model="innerFormData.question"
					:placeholder="handlePlaceholder('留言内容')"
					maxlength="1000"
					show-word-limit
					rows="5"
				></el-input>
			</el-form-item>
			<el-form-item label="姓名" prop="name">
				<el-input
					v-model="innerFormData.name"
					:placeholder="handlePlaceholder('姓名')"
					maxlength="200"
				></el-input>
			</el-form-item>
			<el-form-item label="手机号" prop="phone">
				<el-input
					v-model="innerFormData.phone"
					:placeholder="handlePlaceholder('手机号')"
					maxlength="50"
					@input="validateNumber"
				></el-input>
			</el-form-item>
			<el-form-item label="电子邮箱" prop="email">
				<el-input
					v-model="innerFormData.email"
					:placeholder="handlePlaceholder('电子邮箱')"
					maxlength="50"
				></el-input>
			</el-form-item>
			<!-- 自定义项 -->
			<template v-if="customItem.length">
				<el-form-item :label="item.name" :prop="item.prop" v-for="item in customItem" :key="item.prop">
					<el-input
						v-model="innerFormData[item.prop]"
						:placeholder="item.placeholder"
						maxlength="50"
					></el-input>
				</el-form-item>
			</template>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取消</el-button>
			<el-button :loading="loading" type="primary" @click="submitForm">提交</el-button>
		</div>
	</el-dialog>
</template>

<script setup>
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import { ref, computed, watch } from 'vue';
import { useCommonData } from '@/hooks';
import { getUuid } from '@/utils/api';
const props = defineProps({
	visible: {
		type: Boolean,
		default: false
	}
});
const emits = defineEmits(['update:visible', 'submit']);

const innerVisible = computed({
	get: () => {
		return props.visible;
	},
	set: val => {
		emits('update:visible', val);
	}
});
const handleClose = () => {
	formRef.value && formRef.value.clearValidate();
	formRef.value && formRef.value.resetFields();
	loading.value = false;
	innerVisible.value = false;
};

const validateNumber = val => {
	// 通过正则表达式验证，只保留数字
	innerFormData.value.phone = val.replace(/\D/g, '');
};

const formRef = ref(null);
const innerFormData = ref({
	question: '',
	name: '',
	phone: '',
	email: ''
});
const validateEmail = (rule, value, callback) => {
	if (!value) {
		rule.required ? callback(new Error('请填写电子邮箱')) : callback();
	} else if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
		callback(new Error('请填写正确的电子邮箱'));
	} else {
		callback();
	}
};
const formRules = ref({
	question: [{ required: true, message: '请填写留言内容', trigger: 'blur' }],
	name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
	phone: [{ required: true, message: '请填写手机号', trigger: 'blur' }],
	email: [{ validator: validateEmail, trigger: 'blur' }]
});
const loading = ref(false);
const submitForm = () => {
	const { name, email, phone, question } = innerFormData.value;
	let customFieldList = [];
	Object.keys(innerFormData.value).forEach(key => {
		if (!['name', 'email', 'phone', 'question'].includes(key)) {
			let target = customItem.value.find(item => item.prop == key);
			customFieldList.push({ name: target.name, value: innerFormData.value[key] });
		}
	});

	formRef.value &&
		formRef.value.validate(valid => {
			if (valid) {
				loading.value = true;
				emits('submit', {
					data: { name, email, phone, question, customFieldList },
					done: () => {
						formRef.value && formRef.value.resetFields();
						loading.value = false;
					}
				});
			}
		});
};

const handlePlaceholder = name => {
	const { customField = [] } = config.value;
	let target = customField.find(item => item.name == name);
	return target?.placeholder;
};

const { getStoreId } = useCommonData();
const config = ref({});
const customItem = ref([]);
const initLeaveWordConfig = () => {
	instance.get(enterprise.getLeaveWordConfig(getStoreId.value)).then(res => {
		config.value = res.data.data;
		config.value.customField.forEach(item => {
			if (item.type == 1) item.prop = getUuid();
			if (item.name == '电子邮箱') formRules.value.email[0].required = item.required;
		});
		customItem.value = config.value.customField.filter(item => item.type == 1);
		customItem.value.forEach(item => {
			formRules.value[item.prop] = [
				{
					required: item.required,
					message: item.placeholder ? item.placeholder : `请填写您的${item.name}`,
					trigger: 'blur'
				}
			];
		});
		console.info(innerFormData.value, 888888888);
	});
};

watch(
	() => getStoreId.value,
	val => {
		if (val) initLeaveWordConfig();
	},
	{
		immediate: true
	}
);
</script>
<style lang="less" scoped>
/deep/.c-custom-dialog {
	width: auto;
	min-width: 360px;
	max-width: 516px;
	background: linear-gradient(180deg, #e3eaff 0%, #fff 19.44%);

	.c-custom-mask-img {
		position: absolute;
		top: 0;
		right: 0;
		width: 240px;
		height: 102px;
		pointer-events: none;
	}

	.el-dialog__header {
		padding: 32px;
		border-bottom: none;

		.el-dialog__title {
			color: #1d2129;
			font-family: 'PingFang SC';
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: 28px; /* 140% */
		}
	}
	.el-dialog__headerbtn {
		top: 30px;
		right: 28px;
	}
	.el-dialog__body {
		padding-top: 16px;
		.el-form-item__content {
			width: 350px;
		}
	}
}
.tip-content {
	padding-bottom: 48px;
	color: #4e5969;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
}
</style>
