import { ref, nextTick } from 'vue';

export const useSchedulePopover = () => {
	const showPopover = ref(false);
	const referenceNode = ref();
	const popoverRef = ref();
	const currScheduleContent = ref({});

	const popoverPosRef = ref({});
	const addPopoverPosRef = (node, id) => {
		popoverPosRef.value[id] = node;
	};
	const clearPopoverPosRef = () => {
		popoverPosRef.value = {};
	};

	const handleShowPopover = (uuid, content) => {
		showPopover.value = false;
		referenceNode.value = popoverPosRef.value[uuid].$el;
		nextTick(() => {
			currScheduleContent.value = { ...content };
			showPopover.value = true;
			nextTick(() => {
				popoverRef.value && popoverRef.value.doShow();
			});
		});
	};
	const handleClosePopover = () => {
		popoverRef.value && popoverRef.value.doClose();
	};

	return {
		showPopover,
		referenceNode,
		popoverRef,
		currScheduleContent,
		addPopoverPosRef,
		clearPopoverPosRef,
		handleShowPopover,
		handleClosePopover
	};
};
