<template>
	<div class="comp-rise-fallRange" :style="calcCompStyle"></div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	size: {
		type: String,
		default: '16'
	},
	type: {
		type: String,
		default: 'up' // up down
	}
});
const calcCompStyle = computed(() => {
	if (props.type == 'up') {
		return `border-left: ${props.size / 2}px solid transparent;border-right: ${
			props.size / 2
		}px solid transparent;border-bottom: ${props.size}px solid #F23D3D;`;
	}
	return `border-left: ${props.size / 2}px solid transparent;border-right: ${
		props.size / 2
	}px solid transparent;border-top: ${props.size}px solid #30BF54;`;
});
</script>

<style lang="less" scoped>
.comp-rise-fallRange {
	display: inline-block;
	width: 0;
	height: 0;
}
</style>
