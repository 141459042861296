<template>
	<div class="c-calendar-v2">
		<!-- 卡片视图 -->
		<template v-if="useCardStyle">
			<div
				class="schedule-card-view"
				:class="{
					'schedule-card-view--noToday': noToday
				}"
			>
				<CalendarStepper :value="calendarValue" @change="calendarStepperChange" />
				<el-calendar
					ref="calendarRef"
					v-model="calendarValue"
					:first-day-of-week="7"
					type="card"
					operations="click,today,prev-month,next-month"
					hide-header
					style="margin-top: 12px"
					@cell-click="cardCellClick"
				>
					<template slot="dateCell" slot-scope="{ date, data }">
						<span v-if="isToday(date)" class="card-today-cell">今</span>
						<span v-else>{{ formatCellDay(data.day) }}</span>
						<!-- 日历圆点样式 -->
						<div
							class="cell-schedule-graphic-wrapper"
							:class="{
								'cell-schedule-graphic-wrapper--selected': data.isSelected,
								'cell-schedule-graphic-wrapper--today': isToday(date)
							}"
						>
							<div
								v-for="(color, index) in generateLimitDisplayedColors(date)"
								:key="index"
								class="cell-schedule-circle"
								:style="insetCalendarColorVar(color)"
							></div>
						</div>
					</template>
				</el-calendar>
			</div>
		</template>
		<!-- 全屏视图 -->
		<template v-else>
			<div class="schedule-full-view">
				<div class="schedule-header-main">
					<el-button plain @click="calendarStepperChange('today')">今天</el-button>
					<CalendarStepper :value="calendarValue" large @change="calendarStepperChange" style="width: 220px">
						<span>{{ formatFullViewDate }}</span>
					</CalendarStepper>
					<div class="schedule-view-control">
						<div
							v-for="(item, index) in viewOption"
							:key="index"
							class="schedule-view-name"
							:class="{ 'schedule-view-name--active': activeView == item.value }"
							@click="changeView(item.value)"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
				<div class="schedule-content-view">
					<keep-alive>
						<component
							ref="calendarRef"
							:is="bindViewComponent"
							:key="viewComponentProps.id"
							v-bind="viewComponentProps"
							:value.sync="calendarValue"
							v-on="viewComponentEvents"
						>
							<template slot="theadCell" slot-scope="scope">
								<template v-if="activeView == 'day'">
									<div class="th-day-name text-overflow">{{ scope.data.calendarName }}</div>
									<div class="th-day-content">
										{{ generateDayViewThead(scope.date, scope.data) }}
									</div>
								</template>
							</template>
							<template slot="dateCell" slot-scope="{ date, data }">
								<div class="cell-block">
									<span v-if="activeView == 'month'" class="cell-span">
										{{ isToday(date) ? '今' : formatCellDay(data.day) }}
									</span>
									<div class="cell-schedule-row-wrapper">
										<template v-for="(item, index) in generateFullViewCellContent(date, data)">
											<CalendarScheduleRow
												:key="index"
												:ref="el => getScheduleRef(el, item.rowId)"
												:data="item"
												@click.stop="handleShowPopover(item.rowId, { date, data, item })"
											/>
										</template>
									</div>
								</div>
							</template>
						</component>
					</keep-alive>
				</div>
			</div>
		</template>

		<!-- 日程气泡信息 控制同时只渲染一个气泡DOM -->
		<el-popover
			v-if="showPopover"
			ref="popoverRef"
			placement="right"
			width="266"
			trigger="click"
			popper-class="schedule-popper"
			:visible-arrow="false"
			:reference="referenceNode"
		>
			<!-- TODO other component -->
			<SchedulePopup
				:cellDate="currScheduleContent.date"
				:cellData="currScheduleContent.data"
				:data="currScheduleContent.item"
			/>
		</el-popover>
	</div>
</template>

<script setup>
import CalendarStepper from '@/components/calendar/calendar-stepper.vue';
import CalendarViewTable from '@/components/calendar/calendar-view-table.vue';
import CalendarMonthView from '@/components/calendar/calendar-month-view.vue';
import CalendarScheduleRow from '@/components/calendar/calendar-schedule-row.vue';
import SchedulePopup from '@/components/calendar/schedule-popup.vue';
import { getUuid, getRelativeDateDesc } from '@/utils/api';
import { useMaxItemsFitInTableCell } from '@/hooks/calendar/useMaxItemsFitInTableCell';
import { useSchedulePopover } from '@/hooks/calendar/useSchedulePopover';
import { useScheduleCore } from '@/hooks/calendar/useScheduleCore';
import { computed, ref, onMounted, watch } from 'vue';
import moment from 'moment';

const props = defineProps({
	value: {
		type: Date
	},
	useCardStyle: {
		type: Boolean,
		default: false
	},
	scheduleData: {
		type: Array,
		default: () => []
	},
	noToday: {
		type: Boolean,
		default: false
	}
	// TODO style controls
});
const emits = defineEmits(['update:value', 'change', 'cell-click']);
const calendarValue = computed({
	get: () => {
		if (!props.value) return new Date();
		return props.value;
	},
	set: val => {
		emits('update:value', val);
	}
});

const getterScheduleData = computed(() => props.scheduleData);

const cacheNow = moment().startOf('day'); // 获取今天的开始时间
const isToday = date => {
	const then = moment(date); // 转化输入的日期
	return cacheNow.isSame(then, 'd'); // 比较两者是否在同一天
};
const formatCellDay = day => {
	return moment(day).format('D');
};

const { insetCalendarColorVar, calculateDateRange, filterDataByTimeRange } = useScheduleCore();

// ---------------- 卡片视图
const calendarRef = ref();
const stepperTypeMapByMonth = {
	prev: 'prev-month',
	next: 'next-month',
	today: 'today'
};
const calendarStepperChange = type => {
	if (activeView.value === 'month') calendarRef.value && calendarRef.value.selectDate(stepperTypeMapByMonth[type]);
	else calendarRef.value && calendarRef.value.selectDate(type);
};

// ---------------- 圆点样式
const extractDistinctColors = date => {
	const range = calculateDateRange(date, 'day');
	const rangeResult = filterDataByTimeRange(range, getterScheduleData.value);
	if (!rangeResult) return [];
	// 颜色去重
	return [...new Set(rangeResult.map(res => res.calendarThemeColor))];
};
const generateLimitDisplayedColors = date => extractDistinctColors(date).slice(0, 6);

// ---------------- 全屏视图
const viewOption = ref([
	{ label: '日', value: 'day' },
	{ label: '周', value: 'week' },
	{ label: '月', value: 'month' }
]);
const activeView = ref('month');
const changeView = val => {
	if (activeView.value === val) return;
	activeView.value = val;
};

const formatFullViewDate = computed(() => {
	if (activeView.value === 'day') {
		const dayVal = moment(calendarValue.value).format('YYYY年MM月DD日');
		return `${dayVal} ${getRelativeDateDesc(calendarValue.value)}`;
	}
	if (activeView.value === 'week') {
		return moment(calendarValue.value).format('YYYY年MM月DD日');
	}
	return moment(calendarValue.value).format('YYYY年MM月');
});

const COMPONENT_NAME_MAP = {
	day: CalendarViewTable,
	week: CalendarViewTable,
	month: CalendarMonthView
};
const bindViewComponent = computed(() => {
	return COMPONENT_NAME_MAP[activeView.value];
});
const viewComponentProps = computed(() => {
	if (activeView.value === 'day') return { id: 'day', type: 'day' };
	if (activeView.value === 'week') return { id: 'week', type: 'week' };
	return { id: 'month' };
});
const viewComponentEvents = computed(() => {
	if (activeView.value === 'day' || activeView.value === 'week') {
		return {
			'cell-click': clickDayCell
		};
	}
	return {
		'cell-click': clickMonthCell
	};
});
const clickDayCell = ({ date }) => {
	openCellDialog(date);
};
const clickMonthCell = value => {
	openCellDialog(value);
};
const openCellDialog = value => {
	emits('cell-click', {
		date: value,
		view: activeView.value
	});
};
const cardCellClick = value => {
	openCellDialog(value);
};

const generateDayViewThead = (date, data) => {
	const _calendarId = data.id;
	const range = calculateDateRange(date, 'day');
	const rangeResult = filterDataByTimeRange(range, getterScheduleData.value);
	const calendarScheduleByDay = (rangeResult || []).filter(v => _calendarId && _calendarId === v.calendarVO.id);
	return `共 ${calendarScheduleByDay.length} 个日程`;
};

const getCellDataByDate = date => {
	const isMonth = activeView.value === 'month';
	const range = calculateDateRange(date, isMonth ? 'day' : 'hour');
	return filterDataByTimeRange(range, getterScheduleData.value, !isMonth);
};
/**
 * 根据日期生成日程信息
 * @param {Date} date - 当前单元格日期时间戳
 * @param {Object} data - 当前单元格所携带的信息
 * @returns {Array} - 返回一个数组，包含要渲染的日程对象
 */
const generateFullViewCellContent = (date, data) => {
	if (!isReadyToRenderSchedules.value) return [];

	let cellData = getCellDataByDate(date);
	if (!cellData) return [];

	if (activeView.value === 'day') {
		// 日视图的计算维度 是按照单个日历做的展示
		cellData = cellData.filter(v => data.calendarId && data.calendarId === v.calendarVO.id);
	}

	// 补充唯一id 用于节点匹配
	// PS：这个id并不是数据维度的唯一id
	const rowId = getUuid();
	cellData = cellData
		.map(v => {
			return {
				...v,
				rowId
			};
		})
		.sort((a, b) => a.scheduleStartTime - b.scheduleStartTime);

	const maxCount = maxCountInCell.value;
	if (cellData.length > maxCount) {
		return cellData
			.slice(0, maxCount - 1)
			.concat({ rowId, type: 'non', value: cellData.length - (maxCount - 1), fullSchedule: cellData });
	}
	return cellData.slice(0, maxCount);
};

// 动态计算单元格内容的最大展示个数
const isReadyToRenderSchedules = ref(false);
const { maxCountInCell, recalcMaxItemsInCell } = useMaxItemsFitInTableCell({
	selector: '.cell-schedule-row-wrapper',
	inited: () => {
		isReadyToRenderSchedules.value = true;
	}
});
onMounted(() => {
	recalcMaxItemsInCell();
});
watch(
	() => activeView.value,
	() => {
		recalcMaxItemsInCell();
	}
);

// ---------------- 弹窗控制
const {
	showPopover,
	referenceNode,
	popoverRef,
	currScheduleContent,
	addPopoverPosRef,
	clearPopoverPosRef,
	handleShowPopover
} = useSchedulePopover();
const getScheduleRef = (elm, elmId) => {
	if (elm) {
		addPopoverPosRef(elm, elmId);
	}
};
watch(
	() => getterScheduleData.value,
	() => {
		clearPopoverPosRef();
	}
);
</script>

<style lang="less">
.schedule-popper {
	&.el-popover {
		padding: 16px;
		border-radius: 2px;
		background: #fff;
		box-shadow: 1px 4px 16px rgba(0, 0, 0, 0.08);
		border: none;
	}
	&.el-popper[x-placement^='right'] {
		margin-left: 2px;
	}
	&.el-popper[x-placement^='left'] {
		margin-right: 2px;
	}
}
</style>
<style lang="less" scoped>
.c-calendar-v2 {
	height: 100%;

	/deep/.el-calendar__body .el-calendar-table .el-calendar-day {
		position: relative;

		// 模拟无hover的色值效果
		&:hover {
			background: rgba(57, 100, 229, 0.03);
		}
	}
	/deep/.el-calendar__body td.is-selected .el-calendar-day {
		background: rgba(57, 100, 229, 0.03);
	}
}

.schedule-card-view {
	&--noToday {
		/deep/ .el-calendar__card .el-calendar-table td.is-selected .el-calendar-day {
			background: transparent !important;
		}
		/deep/ .el-calendar__card .el-calendar-table td.is-selected {
			color: var(--theme-color) !important;
		}
		/deep/ .cell-schedule-graphic-wrapper--selected .cell-schedule-circle {
			background: var(--calendar-color) !important;
		}
	}

	.card-today-cell {
		font-size: 12px !important;
	}

	.cell-schedule-graphic-wrapper {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 2px;
		margin: auto;
		display: flex;
		justify-content: center;

		.cell-schedule-circle {
			width: 4px;
			height: 4px;
			background: var(--calendar-color);
			border-radius: 50%;
			box-shadow: -1px 0px #fff;

			&:first-child {
				box-shadow: none;
			}
		}
		&--selected {
			.cell-schedule-circle {
				background: #fff;
				box-shadow: -1px 0px var(--theme-color);
			}
		}
	}
}

.schedule-full-view {
	height: 100%;
	display: flex;
	flex-direction: column;

	.schedule-header-main {
		flex: none;
	}
	.schedule-content-view {
		flex: 1;
		height: 0;
	}
}
.schedule-header-main {
	padding: 0 20px;
	height: 68px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--border-lighter-color);

	.schedule-view-control {
		padding: 2px;
		width: 142px;
		text-align: center;
		display: flex;
		border-radius: 2px;
		background: var(--fill-normal-color);

		.schedule-view-name {
			flex: 1;
			font-family: 'PingFang SC';
			font-size: 14px;
			font-style: normal;
			height: 28px;
			line-height: 28px;
			color: var(--secondary-info-color);
			border-radius: 2px;
			transition: all 0.2s ease-in-out;
			cursor: pointer;

			&--active {
				font-weight: 500;
				color: var(--theme-color);
				background: #fff;
			}
		}
	}
}
.schedule-content-view {
	padding: 20px 20px 20px 0;
}
.cell-block {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.cell-span {
	display: inline-block;
	margin-bottom: 8px;
}
.cell-schedule-row-wrapper {
	flex: 1;
	height: 0;
}
</style>
