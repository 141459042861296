import { getCurrentInstance } from 'vue';
import { FILE_VIEWER_URL } from '@/utils/host';
import { isBuild } from '@/const/host';
import { Base64_encode } from '@/utils/encrypt';
import moment from 'moment';

// 访问vuex
export const useStore = () => {
	const vm = getCurrentInstance();
	if (!vm) throw new Error('must be called in setup');
	return vm.proxy.$store;
};
// 访问router
export const useRouter = () => {
	const vm = getCurrentInstance();
	if (!vm) throw new Error('must be called in setup');
	return vm.proxy.$router;
};
// 访问route
export const useRoute = () => {
	const vm = getCurrentInstance();
	if (!vm) throw new Error('must be called in setup');
	return vm.proxy.$route;
};

export const isArray = o => {
	return Object.prototype.toString.call(o) === '[object Array]';
};
export const isObject = o => {
	return Object.prototype.toString.call(o) === '[object Object]';
};
export const isFunction = o => {
	return (
		Object.prototype.toString.call(o) === '[object Function]' ||
		Object.prototype.toString.call(o) === '[object AsyncFunction]'
	);
};
export const isString = o => {
	return Object.prototype.toString.call(o) === '[object String]';
};
export const isNumber = o => {
	return Object.prototype.toString.call(o) === '[object Number]';
};
export const isBoolean = o => {
	return Object.prototype.toString.call(o) === '[object Boolean]';
};
export const isDate = o => {
	return Object.prototype.toString.call(o) === '[object Date]';
};
export const isNull = o => {
	return Object.prototype.toString.call(o) === '[object Null]';
};
export const isUndefined = o => {
	return Object.prototype.toString.call(o) === '[object Undefined]';
};

/** 获取随机数 */
export const getUuid = (radix, len = 8) => {
	var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
	let uuid = [];
	let i;
	radix = radix || chars.length;
	if (len) {
		for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
	} else {
		let r;
		uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
		uuid[14] = '4';
		for (i = 0; i < 36; i++) {
			if (!uuid[i]) {
				r = 0 | (Math.random() * 16);
				uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
			}
		}
	}
	return uuid.join('');
};

export const debounce = (func, wait = 300, immediate = false) => {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export const throttle = (func, delay = 300) => {
	let lastCall = 0;
	return function () {
		let now = Date.now();
		if (now - lastCall < delay) {
			return;
		}
		lastCall = now;
		return func.apply(this, arguments);
	};
};

/**
 * 将数字转换为带中文单位的字符串
 * @param {number} amount - 需要转换的数字
 * @return {string} - 转换后的字符串
 */
export const convertNumberToCNUnit = amount => {
	// 检查输入是否为数字
	if (typeof amount !== 'number') {
		return { value: '- -', unit: '' };
	}

	// 定义单位和对应的值
	const units = [
		{ name: '万亿', value: 1e12 },
		{ name: '亿', value: 1e8 },
		{ name: '万', value: 1e4 }
	];

	// 遍历单位，找到合适的单位进行转换
	for (let unit of units) {
		if (amount >= unit.value) {
			return { value: (amount / unit.value).toFixed(2), unit: unit.name };
		}
	}

	// 如果没有合适的单位，直接返回原值
	return { value: amount.toFixed(2), unit: '' };
};
export const convertNumberToStr = num => {
	const obj = convertNumberToCNUnit(num);
	return obj.value + obj.unit;
};

export const isNonValue = value => {
	// if (value == undefined || value === '' || Number.isNaN(value)) return true;
	if (value == undefined || value === '' || Number.isNaN(value) || Number(value) == 0) return true;
	return false;
};
export const formatDefault = value => {
	if (isNonValue(value)) {
		return '-';
	}
	return value;
};

/**
 * 在线预览文件
 * @param { String } url 文件地址
 */
export function previewFile(url, watermark = false) {
	let _url = url;
	if (isBuild) {
		// 域名切换
		_url = _url.replace('comein-files.oss-cn-hangzhou.aliyuncs.com', 'media.comein.cn');
	}

	if (!/comein\.cn/i.test(_url)) {
		window.open(_url);
		return;
	}

	let link = `${FILE_VIEWER_URL}${encodeURIComponent(
		Base64_encode(_url)
	)}&officePreviewSwitchDisabled=true&officePreviewType=pdf`;
	if (!watermark) {
		link += `&watermarkTxt=${encodeURIComponent('')}`;
	}

	window.open(link);
}

// 删除对象属性值为空的属性
export const removeProperty = obj => {
	Object.keys(obj).forEach(item => {
		if (
			obj[item] === '' ||
			obj[item] === undefined ||
			obj[item] === 'undefined' ||
			obj[item] === null ||
			obj[item] === 'null'
		)
			delete obj[item];
		if (obj[item] instanceof Array && obj[item].length <= 0) delete obj[item];
	});
	return obj;
};
export const deepRemoveProperty = obj => {
	Object.keys(obj).forEach(item => {
		if (typeof obj[item] === 'object' && obj[item] !== null) {
			deepRemoveProperty(obj[item]);
		}
	});
	return removeProperty(obj);
};

export const generateRandomString = length => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const getRelativeDateDesc = inputDate => {
	var today = moment();
	var tomorrow = moment().add(1, 'days');
	var yesterday = moment().subtract(1, 'days');

	if (moment(inputDate).isSame(today, 'day')) {
		return '今天';
	} else if (moment(inputDate).isSame(tomorrow, 'day')) {
		return '明天';
	} else if (moment(inputDate).isSame(yesterday, 'day')) {
		return '昨天';
	} else {
		let d = moment(inputDate);
		d.locale('zh-cn');
		return d.format('dddd').replace('星期', '周'); // 返回周几
	}
};
