import { ref, watch } from 'vue';

/** 通用的交互状态控制 */
export const useDataState = () => {
	const loading = ref(false);
	const empty = ref(false);
	const data = ref(null);
	const setLoading = val => {
		loading.value = val;
	};
	const setEmpty = val => {
		empty.value = val;
	};
	const setData = val => {
		data.value = val;
	};

	watch(
		() => data.value,
		val => {
			const isArrayData = Array.isArray(val);
			if (isArrayData) {
				if (val.length) {
					setEmpty(false);
				} else {
					setEmpty(true);
				}
				return;
			}

			if (val) {
				setEmpty(false);
			} else {
				setEmpty(true);
			}
		},
		{ deep: true }
	);

	return {
		loading,
		empty,
		data,
		setLoading,
		setEmpty,
		setData
	};
};
