import Vue from 'vue';
import Vuex from 'vuex';
import * as modules from './modules';
import { CACHE_COMMON_DATA, CACHE_APP_VERSION_KEY, APP_VERSION_NUMBER } from '@/const/host';

Vue.use(Vuex);

function flattenArray(arr) {
	return arr.reduce((acc, val) => (Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val)), []);
}

export default new Vuex.Store({
	state: {
		appVersion: null,
		commonData: {},
		stockSelectIndex: 0
	},
	getters: {
		getAppVersion(state) {
			if (state.appVersion) {
				return state.appVersion;
			}
			return sessionStorage.getItem(CACHE_APP_VERSION_KEY) || null;
		},
		getCommonData(state) {
			if (JSON.stringify(state.commonData) != '{}') {
				return state.commonData;
			}
			// 拿不到 state 的时候才检查缓存，比如刷新页面的时候
			let cacheCommonData = sessionStorage.getItem(CACHE_COMMON_DATA);
			cacheCommonData = cacheCommonData && JSON.parse(cacheCommonData);
			return cacheCommonData || {};
		},
		getPageAllModules(state, getters) {
			const data = getters.getCommonData;
			const { pageMenuVOS } = data;
			const moduleGroups = (pageMenuVOS || []).map(menu => menu.moduleVOS || []);
			const simplifiedModules = flattenArray(moduleGroups).map(module => {
				return {
					...module
				};
			});
			return simplifiedModules;
		}
	},
	mutations: {
		resetVersion(state) {
			state.appVersion = APP_VERSION_NUMBER;
			sessionStorage.setItem(CACHE_APP_VERSION_KEY, APP_VERSION_NUMBER);
		},
		setVersion(state, data) {
			state.appVersion = data;
			sessionStorage.setItem(CACHE_APP_VERSION_KEY, data);
		},

		setCommonData(state, { data, overwrite = true }) {
			const preCommonData = this.getters.getCommonData;

			let realData;
			// 是否覆盖之前的信息 默认覆盖
			if (overwrite) {
				realData = { ...data };
			} else {
				realData = { ...preCommonData, ...data };
			}
			state.commonData = realData;

			// 绑定颜色变量
			this.commit('storeTheme/bindAppThemeStyle', realData.themeColorsHex);
			sessionStorage.setItem(CACHE_COMMON_DATA, JSON.stringify(realData));
		},
		setColumnData(state, data) {
			this.commit('setCommonData', {
				data: { innerColumnData: data },
				overwrite: false
			});
		},
		setStockSelectIndex(state, data) {
			state.stockSelectIndex = data;
		}
	},
	actions: {},
	modules: { ...modules }
});
