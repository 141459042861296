// 移动端埋点结构
export const MOBILE_TRACK_CONFIG_TREE = {
	root: {
		children: [
			{
				nid: 282,
				module: '企业号对外展示',
				curPage: '投资者关系专栏',
				content: '浏览页面'
			},
			{
				nid: 283,
				module: '企业号对外展示',
				curPage: '投资者关系专栏',
				content: '查看详情'
			}
		]
	}
};

/**
 * 根据目标编号获取路径以及对应的module和curPage
 * @param {string} targetId - 目标编号
 * @returns {Array} - 包含匹配路径、module和curPage
 */
export function getTrackConfigById(targetId) {
	const [path, module, curPage] = searchNode(MOBILE_TRACK_CONFIG_TREE.root, targetId, '', '', '');
	return {
		content: path,
		module,
		curPage
	};
}

/**
 * 递归搜索节点
 * @param {Object} node - 当前遍历的节点
 * @param {string} targetId - 目标编号
 * @param {string} currentPath - 当前路径
 * @param {string} module - 当前层级的module
 * @param {string} curPage - 当前层级的curPage
 * @returns {Array} - 包含匹配路径、module和curPage
 */
function searchNode(node, targetId, currentPath, module = '', curPage = '') {
	// 如果节点没有content属性且没有子节点，跳过
	if (!node.content && !node.children) {
		return ['', module, curPage];
	}

	// 构建当前节点的路径
	const newPath = currentPath ? `${currentPath}/${node.content}` : node.content;

	// 如果找到目标编号，返回完整路径、module和curPage
	if (node.nid === targetId) {
		return [newPath, node.module || module, node.curPage || curPage];
	}

	// 更新当前层级的module和curPage
	const currentNodeModule = node.module || module;
	const currentNodeCurPage = node.curPage || curPage;

	// 递归搜索子节点
	for (const child of node.children || []) {
		const [foundPath, foundModule, foundCurPage] = searchNode(
			child,
			targetId,
			newPath,
			currentNodeModule,
			currentNodeCurPage
		);
		if (foundPath) {
			return [foundPath, foundModule, foundCurPage];
		}
	}

	// 如果在当前分支未找到，则返回空字符串和当前层级的module、curPage
	return ['', currentNodeModule, currentNodeCurPage];
}
