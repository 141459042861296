import { previewFile } from '@/utils/api';
import Router from '@/router/index';
import { Message } from 'irm-ui';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import { IRSTORE_T_CLICK } from '@/utils/track';
import store from '@/store';

const getReportDetail = async (reportId, reportType) => {
	return instance
		.post(enterprise.researchReportDetail(), {
			reportId: reportId,
			reportType: reportType
		})
		.then(response => {
			return response.data?.data || {};
		})
		.catch(() => {
			return {};
		});
};
const previewReport = async item => {
	if (item.url) {
		// 有返回研报链接 直接打开链接
		return previewFile(item.url);
	}

	// 获取研报纪要
	const detailResult = await getReportDetail(item.reportId, item.type);
	// 是否返回conclusion
	const { conclusion } = detailResult;
	if (conclusion) {
		return Router.push({
			name: 'researchDetail',
			query: {
				id: item.reportId,
				type: item.type
			}
		});
	}

	Message.error('研报内容丢失');
};

/**
 * 公告打开链接地址，没有链接通过id获取， 并判断是否失效
 * @param comeinLink {String} 公告地址
 * @param id {String, Number} 公告id
 */
export const previewAnnounce = ({ comeinLink, id }) => {
	if (comeinLink) {
		previewFile(comeinLink);
	} else {
		instance.post(enterprise.annolink, { id: id }).then(res => {
			if (res.data.code == 0) {
				if (res.data.data) {
					previewFile(res.data.data);
				} else {
					Message.error('该文件地址失效，暂不支持打开');
				}
			}
		});
	}
};

/**
 *
 * @param {*} info 文件信息
 * @param {*} type report:研报 announce:公告 custom:自定义模块
 */
export const handleDetail = (info, type, option = {}) => {
	const { pageId, storeId } = Router.currentRoute.query;
	const allModules = store.getters.getPageAllModules;

	if (type === 'report') {
		const findModule = allModules.find(module => module.moduleId === 'report');
		IRSTORE_T_CLICK(283, {
			params: {
				点击内容名称: findModule?.name || '券商研报',
				点击内容类型: '自定义添加内容',
				内容ID: info.id,
				企业ID: storeId,
				页面ID: pageId
			}
		});
		return previewReport(info);
	} else if (type === 'announce') {
		const findModule = allModules.find(module => module.moduleId === 'announcement');
		IRSTORE_T_CLICK(283, {
			params: {
				点击内容名称: findModule?.name || '公司公告',
				点击内容类型: '自定义添加内容',
				内容ID: info.id,
				企业ID: storeId,
				页面ID: pageId
			}
		});
		return previewAnnounce(info);
	} else if (type === 'custom') {
		const { moduleId } = option;
		const findModule = allModules.find(module => module.moduleId === moduleId);
		IRSTORE_T_CLICK(283, {
			params: {
				点击内容名称: findModule?.name || '自定义模块',
				点击内容类型: '自定义添加内容',
				内容ID: info.id,
				企业ID: storeId,
				页面ID: pageId
			}
		});
		if (info.fileUrl) {
			previewFile(info.fileUrl);
		} else if (info.url) {
			window.open(info.url, '_blank');
		}
	}
};
