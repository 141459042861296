template
<template>
	<div class="wrapper-box" :class="{ 'wrapper-box--half': col == '50%' }">
		<CCalendarV2
			:value.sync="dateValue"
			:scheduleData="scheduleData"
			:noToday="latestSchedule.length > 0"
			useCardStyle
			@cell-click="handleCellClick"
			class="calendar-wrap"
		></CCalendarV2>
		<div class="calendar-content">
			<!-- 两种样式 -->
			<div v-if="col == '50%'" class="calendar-half-wrap">
				<template v-if="latestSchedule.length">
					<div v-for="(item, index) in latestSchedule" :key="index" class="calendar-half-row">
						<div class="calendar-half-date">{{ formatHalfDate(item.date) }}</div>
						<CalendarScheduleRow
							v-for="(item2, index2) in item.scheduleList"
							:key="index2"
							:ref="el => getScheduleRef(el, item2.rowId)"
							:data="item2"
							@click.stop="
								handleShowPopover(item2.rowId, {
									date: item2.date,
									data: item2.data,
									item: item2
								})
							"
						/>
					</div>
				</template>
				<template v-else>
					<div class="calendar-half-date">{{ formatHalfDate(dateValue) }}</div>
					<Empty v-if="!generateFullViewCellContent.length" />
					<template v-else>
						<CalendarScheduleRow
							v-for="(item, index) in generateFullViewCellContent"
							:key="index"
							:ref="el => getScheduleRef(el, item.rowId)"
							:data="item"
							@click.stop="
								handleShowPopover(item.rowId, {
									date: item.date,
									data: item.data,
									item
								})
							"
						/>
					</template>
				</template>
			</div>
			<template v-else>
				<template v-if="latestSchedule.length">
					<div v-for="(item, index) in latestSchedule" :key="index" class="calendar-half-row">
						<div class="calendar-data-date">{{ formatCalendarDate(item.date) }}</div>
						<div class="mt-6">
							<CalendarScheduleCard
								v-for="(item2, index2) in item.scheduleList"
								:key="index2"
								:item="item2"
							/>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="calendar-data-date">{{ formatCalendarDate(dateValue) }}</div>
					<div class="calendar-data-scroll mt-6">
						<el-scrollbar>
							<Empty v-if="!scheduleByCurDay.length" />
							<template v-else>
								<CalendarScheduleCard
									v-for="(item, index) in scheduleByCurDay"
									:key="index"
									:item="item"
								/>
							</template>
						</el-scrollbar>
					</div>
				</template>
			</template>
		</div>

		<el-popover
			v-if="showPopover"
			ref="popoverRef"
			placement="right"
			width="266"
			trigger="click"
			popper-class="schedule-popper"
			:visible-arrow="false"
			:reference="referenceNode"
		>
			<SchedulePopup
				:cellDate="currScheduleContent.date"
				:cellData="currScheduleContent.data"
				:data="currScheduleContent.item"
			/>
		</el-popover>
	</div>
</template>

<script setup>
import CCalendarV2 from '@/components/calendar/c-calendar-v2.vue';
import CalendarScheduleRow from '@/components/calendar/calendar-schedule-row.vue';
import CalendarScheduleCard from '@/components/calendar/calendar-schedule-card.vue';
import SchedulePopup from '@/components/calendar/schedule-popup.vue';
import { ref, watch, computed, defineExpose } from 'vue';
import { enterprise } from '@/axios/interface.js';
import instance from '@/axios';
import { useStore, getUuid } from '@/utils/api';
import { useScheduleCache } from '@/hooks/calendar/useScheduleCache';
import { useScheduleCore } from '@/hooks/calendar/useScheduleCore';
import { useSchedulePopover } from '@/hooks/calendar/useSchedulePopover';
import moment from 'moment';
import { Message } from 'irm-ui';

defineProps({
	col: {
		type: String,
		default: '100%'
	}
});

const store = useStore();
const dateValue = ref();
const scheduleData = ref([]);

const { calculateDateRange, filterDataByTimeRange } = useScheduleCore();

// ---------------- 近三条日程
const latestSchedule = ref([]);
const loadScheduleLatestThree = () => {
	const { storeId } = store.getters.getCommonData;
	instance
		.post(enterprise.getScheduleLatestThree(storeId), {
			pageNum: 0,
			pageSize: 3
		})
		.then(res => {
			const latest = (res.data?.data || []).map(item => {
				return {
					date: item.scheduleStartTime,
					scheduleList: transferAdditionalSchedule(item.scheduleStartTime, [item])
				};
			});
			latestSchedule.value = latest;
		});
};
const handleCellClick = () => {
	// 如果切换过其他的日期，后续展示的日程数据就以选中的日期为基准
	if (latestSchedule.value.length) latestSchedule.value = [];
};

const scheduleByCurDay = ref([]);
watch(
	() => dateValue.value,
	val => {
		const range = calculateDateRange(val, 'day');
		const rangeResult = filterDataByTimeRange(range, scheduleData.value);
		if (rangeResult) {
			scheduleByCurDay.value = rangeResult;
		} else {
			scheduleByCurDay.value = [];
		}
	},
	{ immediate: true }
);

const formatCalendarDate = date => moment(date).format('YYYY年 MM月DD日');
const formatHalfDate = date => moment(date).format('MM月DD日');

const generateFullViewCellContent = computed(() => transferAdditionalSchedule(dateValue.value, scheduleByCurDay.value));
const transferAdditionalSchedule = (date, cellData) => {
	if (!cellData) return [];

	// 补充唯一id 用于节点匹配
	// PS：这个id并不是数据维度的唯一id
	const rowId = getUuid();

	const additionalData = item => ({
		rowId,
		date: moment(date).startOf('day'),
		data: {
			day: moment(date).format('YYYY-MM-DD'),
			calendarId: item.calendarVO.id
		}
	});
	cellData = cellData
		.map(v => {
			return {
				...v,
				...additionalData(v)
			};
		})
		.sort((a, b) => a.scheduleStartTime - b.scheduleStartTime);

	const maxCount = 3;
	if (cellData.length > maxCount) {
		return cellData.slice(0, maxCount - 1).concat({
			rowId,
			type: 'non',
			value: cellData.length - (maxCount - 1),
			fullSchedule: cellData,
			...additionalData({ calendarVO: {} })
		});
	}
	return cellData.slice(0, maxCount);
};

let loadingMessageVm;
const closeLoadingMsgVm = () => {
	if (loadingMessageVm) loadingMessageVm.close();
};
const { updateScheduleRender, resetCachedScheduleMonthKeys } = useScheduleCache({
	onRenderUpdate: () => {
		closeLoadingMsgVm();
		loadingMessageVm = Message({
			message: '加载中...',
			customClass: 'vm-loading-message'
		});
		resetScheduleData().then(closeLoadingMsgVm);
	}
});
watch(
	() => dateValue.value,
	val => {
		updateScheduleRender(val);
	}
);

// ---------------- 弹窗控制
const {
	showPopover,
	referenceNode,
	popoverRef,
	currScheduleContent,
	addPopoverPosRef,
	clearPopoverPosRef,
	handleShowPopover
} = useSchedulePopover();
const getScheduleRef = (elm, elmId) => {
	if (elm) {
		addPopoverPosRef(elm, elmId);
	}
};
watch(
	() => scheduleData.value,
	() => {
		clearPopoverPosRef();
	}
);

const queryScheduleList = () => {
	const currDate = new Date(dateValue.value);

	// 日程请求区间 当前月份加上前后月
	const rangeStart = moment(currDate).subtract(1, 'months').startOf('month').valueOf();
	const rangeEnd = moment(currDate).add(1, 'months').endOf('month').valueOf();
	const range = [rangeStart, rangeEnd];

	const _cache = []; // 覆盖缓存
	[moment(currDate).format('YYYYMM'), moment(rangeStart).format('YYYYMM'), moment(rangeEnd).format('YYYYMM')].forEach(
		d => {
			_cache.push(d);
		}
	);
	// 记录已经拿到数据的日期信息
	resetCachedScheduleMonthKeys([...new Set(_cache)]);

	const { storeId } = store.getters.getCommonData;
	return instance
		.post(enterprise.apiPostScheduleList(storeId), {
			startTime: range[0],
			endTime: range[1]
		})
		.then(res => {
			return res.data?.data || [];
		})
		.catch(() => {
			return [];
		});
};

const resetScheduleData = async () => {
	const result = await queryScheduleList();
	scheduleData.value = result;
	return Promise.resolve(true);
};

const refreshComponent = async () => {
	loadScheduleLatestThree();

	await resetScheduleData();
	dateValue.value = new Date();
};

defineExpose({
	refreshComponent
});
</script>

<style lang="less">
.vm-loading-message {
	min-width: fit-content !important;
}
.schedule-popper {
	&.el-popover {
		padding: 16px;
		border-radius: 2px;
		background: #fff;
		box-shadow: 1px 4px 16px rgba(0, 0, 0, 0.08);
		border: none;
	}
	&.el-popper[x-placement^='right'] {
		margin-left: 2px;
	}
	&.el-popper[x-placement^='left'] {
		margin-right: 2px;
	}
}
</style>
<style lang="less" scoped>
.wrapper-box {
	height: 320px !important;
	display: flex;

	&--half {
		.calendar-wrap {
			width: 244px !important;
		}
	}

	/deep/.el-scrollbar {
		height: 100%;
	}
	/deep/ .el-scrollbar .el-scrollbar__wrap {
		overflow-x: hidden;
	}

	.calendar-wrap {
		width: 388px;
	}
	.calendar-content {
		flex: 1;
		width: 0;
		height: 100%;
		margin-left: 46px;
		display: flex;
		flex-direction: column;

		.calendar-data-scroll {
			flex: 1;
			height: 0;
		}
		.calendar-data-date {
			position: relative;
			color: #1d2129;
			font-family: 'PingFang SC';
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 150% */
		}

		.calendar-half-wrap {
			height: 100%;
		}
		.calendar-half-row {
			& + .calendar-half-row {
				margin-top: 24px;
			}
		}
		.calendar-half-date {
			margin-bottom: 8px;
			color: #1d2129;
			font-family: 'PingFang SC';
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 18px; /* 128.571% */
		}
	}
}
</style>
