import { COMEIN_URL } from '@/utils/host';

export const common = {
	// 通用接口
	phpUrl: COMEIN_URL + '/index.php'
};

export const enterprise = {
	getPageDetail: (storeId, pageId) => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/page/${pageId}`,
	getPageSaveContent: (storeId, pageId) => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/innerPage/${pageId}`,
	news: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/news`,
	newsDetail: (storeId, newsId) => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/news/${newsId}`,
	reportList: `${COMEIN_URL}/irmcenter/anonymous/irstore/report/list`,
	researchReportDetail: () => `${COMEIN_URL}/irmcenter/anonymous/irstore/research-report-detail`,
	getAnnouncements: storeId => `${COMEIN_URL}/irmcenter/v2/anonymous/irstore/${storeId}/announcements`,
	getAnnouncementsByStock: fullCode => `${COMEIN_URL}/irmcenter/v3/anonymous/irstore/${fullCode}/announcements`,
	getAdvisoryList: `${COMEIN_URL}/irmcenter/anonymous/irm/store/advisory/list`, 
	getCustomModuleContent: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/moduleContent`,
	getAllActivityList: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/clearance-activity-list`,
	getStockQuote: (market, code) => `${COMEIN_URL}/irmcenter/anonymous/irstore/market/${market}/code/${code}/quote`,
	getStockDividendPlan: fullCode => `${COMEIN_URL}/irmcenter/anonymous/irstore/${fullCode}/stock/dividend-plan`,
	getStockMorthSouthFunds: fullCode =>
		`${COMEIN_URL}/irmcenter/anonymous/irstore/${fullCode}/stock/north-south-funds`,
	getStockMarginFunds: fullCode => `${COMEIN_URL}/irmcenter/anonymous/irstore/${fullCode}/stock/margin-funds`,
	getFinancialStatement: fullCode =>
		`${COMEIN_URL}/irmcenter/anonymous/irstore/${fullCode}/stock/financial-statement`,
	getProfitStatement: fullCode => `${COMEIN_URL}/irmcenter/anonymous/irstore/${fullCode}/stock/profit-statement`,
	getBalanceSheet: fullCode => `${COMEIN_URL}/irmcenter/anonymous/irstore/${fullCode}/stock/balance-sheet`,
	getCashFlowStatement: fullCode => `${COMEIN_URL}/irmcenter/anonymous/irstore/${fullCode}/stock/cash-flow-statement`,
	getQuarterPerformance: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/quarter/performance`,
	getCommonIRStore: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}`,
	getKLineData: (fullCode, t) => `${COMEIN_URL}/irmcenter/anonymous/irstore/${fullCode}/stock/candlestick-chart/${t}`,
	getRoadshowList: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/roadshow`,
	getActivityList: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/activity`,
	getSurveyList: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/summary`,
	annolink: 'json_irstore_annolink',
	createLeaveWord: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/leaveWord`,
	getModulesTabList: (storeId, pageId, moduleId) =>
		`${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/page/${pageId}/customPageTab/${moduleId}`,
	apiPostScheduleList: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/customs/calendar/schedule`,
	apiGetIrstoreCalendarList: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/customs/calendar`,
	getScheduleLatestThree: storeId =>
		`${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/customs/calendar/schedule/top-three`,
	apiPostScheduleDetail: storeId =>
		`${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/customs/calendar/schedule/detail`,
	getLeaveWordConfig: storeId => `${COMEIN_URL}/irmcenter/anonymous/irstore/${storeId}/leaveWord/config`
};
