import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import axios from '@/axios';
import global from '@/components/global.js';

import ElementUI from 'irm-ui';
import 'irm-ui/lib/theme-chalk/index.css';

import 'swiper/dist/css/swiper.css';

import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js';
import '@/style/main.less';

Vue.use(ElementUI);
Vue.use(global);

import DialogComponent from '@/utils/modal.js';
Vue.use(DialogComponent, {
	router,
	store
});

import { trackerInit } from '@/utils/track/index';
// 初始化阿里云SDK
trackerInit();

Vue.config.productionTip = false;
Vue.prototype.$request = axios;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
