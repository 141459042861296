<template>
	<CLoadingBox :loading="loading" :empty="empty">
		<div class="news-wrapper">
			<RowInfoLink
				v-for="(item, index) in newsList"
				:key="index"
				:sort="index + 1"
				:border="index != newsList.length - 1"
				:item="item"
				@click="goNewsDetail(item)"
			>
				<div slot="more" class="news-more">
					<span>详情</span>
					<i class="iconfont icon-flip-right"></i>
				</div>
			</RowInfoLink>
		</div>
	</CLoadingBox>
</template>

<script setup>
import RowInfoLink from '@/views/home/components/RowInfoLink.vue';
import { computed, defineExpose } from 'vue';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import { useCommonData, useDataState } from '@/hooks';
import { useRouter, useStore } from '@/utils/api';
import { IRSTORE_T_CLICK } from '@/utils/track';

const router = useRouter();
const store = useStore();
const goNewsDetail = item => {
	const { id, storeId } = store.getters.getCommonData;
	IRSTORE_T_CLICK(283, {
		params: {
			点击内容名称: '新闻资讯',
			点击内容类型: '自定义添加内容',
			内容ID: item.id,
			企业ID: storeId,
			页面ID: id
		}
	});
	if (item.url) {
		window.open(item.url);
		return;
	}
	router.push({
		name: 'newsDetail',
		query: {
			id: item.id,
			pageId: id,
			storeId: storeId
		}
	});
};

const { getStoreId } = useCommonData();
const { data, loading, empty, setData, setLoading } = useDataState();
const newsList = computed(() => (data.value || []).slice(0, 3));
const fetchList = async () => {
	setLoading(true);
	return instance
		.get(enterprise.news(getStoreId.value), {
			pageNum: 20,
			pageStart: 0
		})
		.then(response => {
			setData(response.data?.data);
			setLoading(false);
		})
		.catch(() => {
			setData([]);
			setLoading(false);
		});
};
const refreshComponent = () => {
	fetchList();
};

defineExpose({
	refreshComponent
});
</script>

<style lang="less" scoped>
.news-wrapper {
	.news-more {
		display: flex;
		align-items: center;
		cursor: pointer;

		span {
			color: #4e5969;
			text-align: right;
			font-family: 'PingFang SC';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 114.286% */
		}
		.iconfont {
			font-size: 16px;
			color: #4e5969;
		}
	}
}
</style>
