<template>
	<CommonTab
		v-if="stockTabList.length"
		type="rect"
		:value.sync="stockTabActValue"
		:option="stockTabList"
		@change="changeStockTab"
	/>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStockVO } from '@/hooks';

const emits = defineEmits(['change']);

const props = defineProps({
	isStockComponent: {
		type: Boolean,
		value: false
	}
});
const stockTabActValue = ref('');
const stockTabList = ref([]);
const { getStockInfoVOSAndUpperCase, selectIdxAtStockInfoVOS, changeStockVOSelectIndex } = useStockVO();
const initStockTab = () => {
	if (props.isStockComponent) { //股票模块暂时将股票代码显示出来
		stockTabList.value = getStockInfoVOSAndUpperCase.value;
	} else {
		// TODO 暂时隐藏
		const skip = true;
		if (skip) return;
		stockTabList.value = getStockInfoVOSAndUpperCase.value;
		stockTabActValue.value = getStockInfoVOSAndUpperCase.value[selectIdxAtStockInfoVOS.value].value;
	}
};
const changeStockTab = ({ index }) => {
	if (props.isStockComponent) {return stockTabActValue.value = ''} //股票代码仅展示
	changeStockVOSelectIndex(index);
	emits('change');
};

onMounted(() => {
	initStockTab();
});
</script>

<style lang="less" scoped></style>
