// 简易的 EventBus

const state = () => ({
	events: new Map()
});

const mutations = {
	ADD_EVENT(state, { event, callback }) {
		if (!state.events.has(event)) {
			state.events.set(event, []);
		}
		state.events.get(event).push(callback);
	},
	REMOVE_EVENT(state, { event, callback }) {
		if (state.events.has(event)) {
			const eventCallbacks = state.events.get(event);
			const index = eventCallbacks.indexOf(callback);
			if (index > -1) {
				eventCallbacks.splice(index, 1);
			}
		}
	},
	EMIT_EVENT(state, { event, payload }) {
		if (state.events.has(event)) {
			state.events.get(event).forEach(callback => callback(payload));
		}
	}
};

const actions = {
	on({ commit }, { event, callback }) {
		commit('ADD_EVENT', { event, callback });
	},
	off({ commit }, { event, callback }) {
		commit('REMOVE_EVENT', { event, callback });
	},
	emit({ commit }, { event, payload }) {
		commit('EMIT_EVENT', { event, payload });
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
