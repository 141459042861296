<template>
	<div class="layout-container">
		<div class="header-banner">
			<template v-if="isEnableUpload">
				<template v-if="realRenderBanner">
					<div class="header-banner-mask">
						<div class="header-banner-change" @click="handleUploadBanner">
							<i class="iconfont icon-icon_shengchenghaibao"></i>
							<div>替换背景</div>
						</div>
					</div>
					<TransitionBanner :src="realRenderBanner" :visible="bannerRendered" />
				</template>
				<div v-else class="header-banner-upload">
					<el-image class="header-banner-upload-bg" :src="LAYOUT_UPLOAD_BG_IMG" fit="cover"></el-image>
					<div class="por" style="text-align: center">
						<div class="banner-upload-title">背景图</div>
						<div class="mt-8 banner-upload-desc">6:1，支持jpg、jpeg、png格式，大小不超过10M。</div>
						<el-button type="primary" size="medium" class="mt-16" @click="handleUploadBanner"
							>上传</el-button
						>
					</div>
				</div>
			</template>
			<TransitionBanner v-else :src="realRenderBanner" :visible="bannerRendered" />
		</div>

		<div v-if="!shouldHideHeaderNav" class="header-nav">
			<div class="header-block">
				<div
					v-for="(tab, index) in navigatorList"
					:key="index"
					class="nav-font"
					:class="{
						'nav-font--current': getCurrentRouteName == tab.name,
						'nav-font--other': getCurrentRouteName != tab.name
					}"
					@click="navigateRoute(tab.name, index)"
				>
					<i v-if="index" class="iconfont icon-flip-right"></i>
					<span>{{ tab.name }}</span>
				</div>
			</div>
		</div>

		<div class="layout-main">
			<!-- 首页固定缓存 -->
			<keep-alive :include="['home']">
				<router-view></router-view>
			</keep-alive>
		</div>

		<div v-if="!isEmptyColumnData" class="layout-footer">
			<el-image :src="LAYOUT_FOOTER_BG" fit="cover" class="footer-bg" />
			<div class="footer-block">
				<div class="footer-left">
					<div class="l-title">联系我们</div>
					<div class="l-row">
						<div class="l-icon">
							<img :src="LAYOUT_PHONE_ICON" alt="" />
						</div>
						<div class="l-span">{{ getColumnData.phone || 'XXXXXXXXXXX' }}</div>
					</div>
					<div class="l-row">
						<div class="l-icon">
							<img :src="LAYOUT_EMAIL_ICON" alt="" />
						</div>
						<div class="l-span">{{ getColumnData.email || 'XXXXXXXXXXXXXX' }}</div>
					</div>
					<div class="l-row">
						<div class="l-icon">
							<img :src="LAYOUT_REGION_ICON" alt="" />
						</div>
						<div class="l-span">{{ getColumnData.address || 'XXXXXXXXXXXXXXXX' }}</div>
					</div>
				</div>
				<div
					v-if="getColumnData.otherChannelList && getColumnData.otherChannelList.length"
					class="footer-right"
				>
					<div v-for="(item, index) in getColumnData.otherChannelList" :key="index" class="channel-item">
						<div class="channel-image">
							<el-image :src="item.otherChannelImage" fit="cover" style="width: 100%; height: 100%" />
						</div>
						<div class="channel-value mt-14">{{ item.name }}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="fixed-action">
			<div v-if="showFeedbackEntry" class="action-block" @click="handleAction('feedback')">
				<SvgIcons name="icon_feedback" class="c-action-svg" />
				<span class="action-block-span">在线留言</span>
			</div>
			<div
				class="action-block"
				:class="{ 'action-block--disabled': scrollDisabled }"
				@click="handleAction('top')"
			>
				<SvgIcons name="icon_top" class="c-action-svg" />
				<span class="action-block-span">返回顶部</span>
			</div>
		</div>
		<CDialogFeedback :visible.sync="visibleFeedback" @submit="handleFeedbackSubmit" />
	</div>
</template>

<script>
import { CACHE_NAVIGATOR_HISTORY } from '@/const/host';
import { IRSTORE_T_VIEW } from '@/utils/track';
import { getIrstoreConfig } from '@/utils/track/map';

export default {
	data() {
		return {
			navigatorList: []
		};
	},
	mounted() {
		this.$router.afterEach(to => {
			this.updateNavigatorHistory(to);
		});

		const history = sessionStorage.getItem(CACHE_NAVIGATOR_HISTORY);
		const isHome = this.$route.name == 'home';
		if (isHome || !history) {
			let pickName = this.$route.meta.title || this.$route.name;
			this.navigatorList.push({
				name: pickName,
				path: this.$route.path
			});
		} else {
			this.navigatorList = JSON.parse(history);
		}
	},
	watch: {
		navigatorList: {
			handler(val) {
				const findModuleData = (val || []).find(item => item.path === this.$route.path);
				if (findModuleData) {
					const { pageId, storeId } = this.$route.query;
					const config = getIrstoreConfig(findModuleData);
					config.params = {
						...config.params,
						企业ID: storeId,
						页面ID: pageId
					};
					IRSTORE_T_VIEW(282, config);
				}
				sessionStorage.setItem(CACHE_NAVIGATOR_HISTORY, JSON.stringify(val));
			}
		}
	},
	computed: {
		getCurrentRouteName() {
			if (this.$route.query.title) return this.$route.query.title; // 优先取地址栏定义的title
			return this.$route.meta.title || this.$route.name;
		},
		shouldHideHeaderNav() {
			const hideRoute = ['home'];
			return hideRoute.includes(this.$route.name);
		}
	},
	methods: {
		// 更新导航历史
		updateNavigatorHistory(to) {
			let pickName = to.meta.title || to.name;
			if (to.query.title) pickName = to.query.title; // 优先取地址栏定义的title
			const findIndex = this.navigatorList.findIndex(route => route.name === pickName);

			if (findIndex === -1) {
				this.navigatorList.push({
					name: pickName,
					path: to.path
				});
			} else {
				this.navigatorList = this.navigatorList.slice(0, findIndex + 1);
			}

			// 首页重置
			if (to.name === 'home') {
				this.navigatorList = [
					{
						name: pickName,
						path: to.path
					}
				];
			}

			sessionStorage.setItem(CACHE_NAVIGATOR_HISTORY, JSON.stringify(this.navigatorList));
		},
		navigateRoute(routeName, idx) {
			if (this.getCurrentRouteName == routeName) return;
			let total = this.navigatorList.length - 1;
			let backCount = total - idx;
			this.$router.go(backCount * -1);
		}
	}
};
</script>
<script setup>
import CDialogFeedback from '@/components/CDialogFeedback.vue';
import LAYOUT_FOOTER_BG from '@/assets/images/layout_footer_bg.png';
import LAYOUT_UPLOAD_BG_IMG from '@/assets/images/layout_upload_bg.png';
import LAYOUT_EMAIL_ICON from '@/assets/images/icon/email.png';
import LAYOUT_PHONE_ICON from '@/assets/images/icon/phonecall.png';
import LAYOUT_REGION_ICON from '@/assets/images/icon/region.png';
import { WEBIRM_HOST } from '@/const/host';
import { useRoute, useStore, debounce } from '@/utils/api';
import { useCommonData } from '@/hooks';
import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { Message } from 'irm-ui';
import SvgIcons from '@/components/SvgIcons.vue';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import TransitionBanner from './components/TransitionBanner.vue';

const route = useRoute();
const { enableUpload } = route.query;
const isEnableUpload = computed(() => enableUpload);

const { getBackgroundImage, getColumnData, getStoreId, getFeedbackEntry } = useCommonData();
const isEmptyColumnData = computed(() => {
	const { phone, email, address, otherChannelList } = getColumnData.value;
	// 全都没有的时候 隐藏底栏
	return !phone && !email && !address && !otherChannelList;
});

// 如果地址栏包括了 storeId/pageId，则判定为会进行数据刷新的场景
// 如果没有，就从缓存中获取
const realRenderBanner = ref('');
const bannerRendered = ref(false);
const store = useStore();
onMounted(() => {
	const { storeId, pageId } = route.query;
	if (storeId && pageId) {
		store.dispatch('storeEvent/on', {
			event: 'banner',
			callback: createImg
		});
	} else {
		createImg(getBackgroundImage.value);
	}
});
const createImg = imgUrl => {
	if (!imgUrl) return;
	const imgElement = document.createElement('img');
	imgElement.src = imgUrl;
	imgElement.onload = () => {
		realRenderBanner.value = imgUrl;
		bannerRendered.value = true;
	};
};

const handleUploadBanner = data => {
	// 向外通知接口更新
	parent.postMessage(
		{
			updateMessage: {
				showUploadBox: true
			}
		},
		WEBIRM_HOST
	);
};

const scrollDisabled = ref(true);
const handleScroll = () => {
	const threshold = 450; // 设置阈值高度，当滚动超过这个高度时切换状态
	const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	scrollDisabled.value = scrollTop < threshold;
};
onMounted(() => {
	window.addEventListener('scroll', handleScroll);
});
onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});

const showFeedbackEntry = ref(false);
watch(
	() => getFeedbackEntry.value,
	val => {
		showFeedbackEntry.value = val;
	},
	{ immediate: true }
);
const handleAction = debounce(
	function (type) {
		const { pageId, storeId } = route.query;
		switch (type) {
			case 'feedback':
				IRSTORE_T_VIEW(282, {
					params: {
						浏览模块名称: '投资者留言',
						浏览模块类型: '系统默认模块',
						企业ID: storeId,
						页面ID: pageId
					}
				});
				visibleFeedback.value = true;
				break;
			case 'top':
				if (scrollDisabled.value) return;
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
				break;

			default:
				break;
		}
	},
	200,
	true
);
const visibleFeedback = ref(false);
const handleFeedbackSubmit = ({ data, done }) => {
	instance
		.post(enterprise.createLeaveWord(getStoreId.value), { ...data })
		.then(response => {
			done();
			visibleFeedback.value = false;
			Message.success('提交成功，感谢您的关注和建议！');
		})
		.catch(() => {
			done();
		});
};
</script>

<style lang="less" scoped>
.layout-container {
	min-width: 1200px;

	.header-banner {
		height: 460px;
		position: relative;
		background: #f7f8fa;

		.header-banner-mask {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 2;
			opacity: 0;
			transition: all 0.17s ease-in-out;
			cursor: pointer;

			.header-banner-change {
				padding: 0 16px;
				height: 32px;
				border-radius: 2px;
				background: #fff;
				color: #3964e5;
				display: inline-flex;
				align-items: center;

				.iconfont {
					font-size: 16px;
				}
				div {
					margin-left: 4px;
					font-family: 'PingFang SC';
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 18px; /* 128.571% */
				}
			}
		}
		&:hover {
			.header-banner-mask {
				opacity: 1;
			}
		}
		.header-banner-upload {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background: #f7f8fa;
			position: relative;

			&-bg {
				position: absolute;
				top: 64px;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				max-width: 1380px;
			}
		}
		.banner-upload-title {
			color: #1d2129;
			font-family: 'PingFang SC';
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px; /* 150% */
		}
		.banner-upload-desc {
			color: #86909c;
			font-family: 'PingFang SC';
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
	}
	.header-nav {
		height: 62px;
		background: #f7f8fa;

		.header-block {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			height: 100%;
			box-sizing: border-box;
		}
	}
	.nav-font {
		font-family: 'PingFang SC';
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;

		&--current {
			font-weight: 500;
			color: #1d2129;
			cursor: default;
		}
		&--other {
			color: #86909c;

			&:hover {
				color: #1d2129;
			}
		}
		.iconfont {
			margin: 0 3px;
			font-size: 16px;
			color: #e5e6e8;
		}
	}

	.layout-main {
		width: 1200px;
		margin: 0 auto;
		background: #fff;
	}
	.layout-footer {
		height: 275px;
		position: relative;

		.footer-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.footer-block {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			position: relative;
		}
		.footer-left {
			.l-title {
				color: #fff;
				font-family: 'PingFang SC';
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: 150%; /* 36px */
				margin-top: 56px;
				margin-bottom: 40px;
			}
			.l-row {
				display: flex;

				& + .l-row {
					margin-top: 14px;
				}
			}
			.l-icon {
				height: 20px;
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: 18px;
				}
			}
			.l-span {
				margin-left: 8px;
				color: #fff;
				font-family: 'PingFang SC';
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 20px;
				max-width: 580px;
			}
		}
		.footer-right {
			margin-top: 100px;
			display: flex;

			.channel-item {
				& + .channel-item {
					margin-left: 24px;
				}
				.channel-image {
					margin: 0 auto;
					width: 90px;
					height: 90px;
					background: #fff;
				}
				.channel-value {
					font-family: PingFang SC;
					font-size: 12px;
					font-weight: 400;
					line-height: 17px;
					text-align: center;
					color: #ffffff;
				}
			}
		}
	}
	.fixed-action {
		position: fixed;
		right: 40px;
		bottom: 64px;
		z-index: 22;
		padding: 20px 4px;
		display: flex;
		flex-direction: column;
		border-radius: 80px;
		background: #fff;
		text-align: center;
		filter: drop-shadow(1px 4px 16px rgba(0, 0, 0, 0.08));
		backdrop-filter: blur(7px);

		.action-block {
			cursor: pointer;

			& + .action-block {
				margin-top: 20px;
			}
			&:not(:first-child) {
				position: relative;

				&::before {
					content: '';
					position: absolute;
					top: -10px;
					left: 12px;
					right: 12px;
					height: 1px;
					background: #f2f3f5;
					transform: scaleY(0.5);
					pointer-events: none;
				}
			}
			&:hover {
				.action-block-span {
					color: #3964e5;
				}
				.c-action-svg {
					color: #3964e5 !important;
				}
			}
			&--disabled {
				cursor: not-allowed;

				.action-block-span {
					color: #c9cdd4;
				}
				.c-action-svg {
					color: #c9cdd4 !important;
				}
				&:hover {
					.action-block-span {
						color: #c9cdd4;
					}
					.c-action-svg {
						color: #c9cdd4 !important;
					}
				}
			}
		}
		.action-block-span {
			display: block;
			margin-top: 8px;
			color: #1d2129;
			font-family: 'PingFang SC';
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
		.c-action-svg {
			font-size: 24px;
			color: #4e5969 !important;
		}
	}
}
</style>
