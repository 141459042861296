<template>
	<div class="schedule-wrapper">
		<div class="schedule-mask" :style="insetCalendarColorVar(selfDetail.calendarThemeColor)">
			<div class="schedule-mask-gradient"></div>
			<el-image
				:src="getCalendarColorImg(selfDetail.calendarThemeColor)"
				fit="cover"
				class="schedule-mask-img"
			></el-image>
		</div>

		<template v-if="selfDetail.type == 'non'">
			<div class="schedule-non-v1">{{ formatDDDD(cellDate) }}</div>
			<div class="schedule-non-v2">{{ formatCellDay(cellData.day) }}</div>
			<div v-loading="moreQueryLoading" class="schedule-non-wrapper">
				<el-scrollbar>
					<div class="schedule-non-block">
						<CalendarScheduleRow
							v-for="(item, index) in selfDetail.fullSchedule"
							:key="index"
							:data="item"
							:shadow="false"
							:ref="el => getScheduleRef(el, index + '-' + item.uuid)"
							@click="
								handleShowPopover(index + '-' + item.uuid, {
									date: cellDate,
									data: cellData,
									item: item
								})
							"
						/>
					</div>
				</el-scrollbar>
			</div>
		</template>
		<template v-else>
			<div class="schedule-block" :style="insetCalendarColorVar(selfDetail.calendarThemeColor)">
				<div class="schedule-before"></div>
				<div
					class="schedule-v1"
					:class="{ 'schedule-v1--link': renderLinkAction }"
					style="color: var(--calendar-color)"
					@click="handleScheduleAction"
				>
					<span class="text-overflow">{{ checkScheduleThemeName }}</span>
					<SvgIcons v-if="renderLinkAction" name="right-light" style="color: var(--calendar-color)" />
				</div>
				<div class="schedule-v2 schedule-time">{{ formatCellFullDate(selfDetail) }}</div>
				<div class="schedule-v2 schedule-time">{{ selfDetail.address }}</div>
				<div v-loading="moreQueryLoading" class="schedule-detail">
					<CScheduleDetail :detail="selfDetail" />
				</div>
			</div>
		</template>

		<el-popover
			v-if="showPopover"
			ref="popoverRef"
			placement="right"
			width="266"
			trigger="click"
			popper-class="schedule-popper"
			:visible-arrow="false"
			:reference="referenceNode"
		>
			<SchedulePopup
				:cellDate="currScheduleContent.date"
				:cellData="currScheduleContent.data"
				:data="currScheduleContent.item"
			/>
		</el-popover>
	</div>
</template>

<script setup>
import moment from 'moment';
import { getRelativeDateDesc, previewFile, useStore } from '@/utils/api';
import { useScheduleCore } from '@/hooks/calendar/useScheduleCore';
import { computed, onMounted, ref } from 'vue';
import CScheduleDetail from '@/components/calendar/c-schedule-detail.vue';
import CalendarScheduleRow from '@/components/calendar/calendar-schedule-row.vue';
import SchedulePopup from '@/components/calendar/schedule-popup.vue';
import { useSchedulePopover } from '@/hooks/calendar/useSchedulePopover';
import { Message } from 'irm-ui';
import SvgIcons from '@/components/SvgIcons.vue';
import { enterprise } from '@/axios/interface.js';
import instance from '@/axios';

const props = defineProps({
	cellDate: {
		// type: Date,
		// default: ''
	},
	cellData: {
		type: Object,
		default: () => {}
	},
	data: {
		type: Object,
		default: () => {}
	}
});
const selfDetail = ref({ ...props.data }); // 默认值
const checkScheduleThemeName = computed(() => {
	const { calendarVO, scheduleTheme } = selfDetail.value;
	const { isOwn, innerPermission } = calendarVO;
	// true:自有 false:订阅
	if (isOwn) return scheduleTheme || '-';
	else {
		// 如他人日程查看权限为「游客」，展示为忙碌
		return innerPermission === 2 ? '忙碌' : scheduleTheme || '-';
	}
});

const renderLinkAction = computed(() => {
	const { type, conferenceId, customsType, announcementVO, newsVO } = selfDetail.value;
	if (type === 0 && conferenceId) return true;
	if (type === 2) {
		if (customsType === 1) return true;
		if (customsType === 2) {
			let realLink = announcementVO.comeinLink || announcementVO.sourceLink;
			if (realLink) return true;
		}
		if (customsType === 3 && newsVO.url) return true;
	}
	return false;
});
const handleScheduleAction = () => {
	const { type, customsType, investmentActivityType, investmentActivityVO, announcementVO, newsVO } =
		selfDetail.value;
	if (type === 2) {
		// 投关
		if (customsType === 1) {
			if (investmentActivityType === 3) {
				if (!investmentActivityVO.url) {
					Message('暂无活动文件');
					return;
				}
				previewFile(investmentActivityVO.url);
			} else {
				if (investmentActivityVO.descUrl) window.open(investmentActivityVO.descUrl);
			}
		}
		// 公告
		if (customsType === 2) {
			let realLink = announcementVO.comeinLink || announcementVO.sourceLink;
			if (realLink) previewFile(realLink);
		}
		// 新闻
		if (customsType === 3 && newsVO.url) {
			window.open(newsVO.url);
		}
	}
};

const { insetCalendarColorVar, getCalendarColorImg } = useScheduleCore();
const formatCellDay = day => {
	return day.slice(8);
};
const formatCellFullDate = date => {
	if (!date.scheduleStartTime) return '-';

	let disVisibleHour = false;
	if (date.customsType === 1 && date.investmentActivityVO.sourceType === 1) {
		// 自主添加
		disVisibleHour = true;
	}
	if (date.customsType === 1 && date.investmentActivityType === 3) {
		disVisibleHour = true;
	}
	if (date.customsType === 2 || date.customsType === 3) {
		disVisibleHour = true;
	}
	if (disVisibleHour) {
		return `${moment(date.scheduleStartTime).format('MM月DD日')}(${getRelativeDateDesc(date.scheduleStartTime)})`;
	}

	const startTimeVal = `
		${moment(date.scheduleStartTime).format('MM月DD日')}(${getRelativeDateDesc(date.scheduleStartTime)}) ${moment(
		date.scheduleStartTime
	).format('HH:mm')}
	`;

	if (!date.scheduleEndTime) return '-';
	const endTimeVal = `
		${moment(date.scheduleEndTime).format(
			moment(date.scheduleStartTime).isSame(date.scheduleEndTime, 'year')
				? moment(date.scheduleStartTime).isSame(date.scheduleEndTime, 'day')
					? 'HH:mm'
					: 'MM月DD日 HH:mm'
				: 'YYYY年MM月DD日 HH:mm'
		)}
	`;
	return `${startTimeVal}-${endTimeVal}`;
};
const formatDDDD = date => {
	let d = moment(date);
	d.locale('zh-cn');
	return d.format('dddd');
};

const store = useStore();
const queryMoreScheduleDetail = async data => {
	const { calendarVO } = data;
	const { isOwn, innerPermission } = calendarVO;
	const isSubscribe = !isOwn;
	// 对应 —— 忙碌
	if (isSubscribe && innerPermission === 2) return Promise.resolve({});

	const { storeId } = store.getters.getCommonData;
	return instance
		.post(
			enterprise.apiPostScheduleDetail(storeId),
			{
				scheduleId: data.scheduleId,
				type: data.type
			},
			{ hideMessage: true }
		)
		.then(res => {
			return res.data?.data || {};
		})
		.catch(() => {
			return {};
		});
};
const moreQueryLoading = ref(false);
onMounted(async () => {
	const tmplSetData = (cur, next) => {
		let tmpl = {};
		for (const key in next) {
			if (!(key in cur && cur[key])) {
				tmpl[key] = next[key];
			}
		}
		return {
			...cur,
			...tmpl
		};
	};

	// 展开列表的时候 一开始不需要获取额外的数据
	if (props.data.type === 'non') return;
	if (props.data.type === 2) return;

	moreQueryLoading.value = true;
	const moreDetail = await queryMoreScheduleDetail(props.data);
	selfDetail.value = tmplSetData(selfDetail.value, moreDetail);
	moreQueryLoading.value = false;
});

const { showPopover, referenceNode, popoverRef, currScheduleContent, addPopoverPosRef, handleShowPopover } =
	useSchedulePopover();
const getScheduleRef = (elm, elmId) => {
	if (elm) {
		addPopoverPosRef(elm, elmId);
	}
};
</script>

<style lang="less" scoped>
.schedule-wrapper {
	position: relative;

	.schedule-mask {
		position: absolute;
		// padding
		top: -16px;
		left: -16px;
		right: -16px;
		height: 120px;
		pointer-events: none;

		.schedule-mask-gradient {
			height: 100%;
			border-radius: 2px;
			background: linear-gradient(180deg, var(--calendar-fade-1-color) 0%, rgba(255, 255, 255, 0) 100%);
		}
		.schedule-mask-img {
			position: absolute;
			top: 0;
			right: 0;
			width: 150px;
			height: 64px;
		}
	}

	.schedule-non-v1 {
		color: var(--emphasis-color);
		font-family: Barlow;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 18px; /* 128.571% */
	}
	.schedule-non-v2 {
		margin-top: 4px;
		color: var(--emphasis-color);
		font-family: Barlow;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: 32px; /* 133.333% */
	}
	.schedule-non-wrapper {
		/deep/.el-scrollbar {
			height: 100%;
			overflow-x: hidden;
		}
	}
	.schedule-non-block {
		margin-top: 16px;
		max-height: 72vh;
	}

	.schedule-time {
		color: var(--emphasis-color);
		font-family: 'PingFang SC';
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px; /* 133.333% */
	}
	.schedule-block {
		padding: 24px 7px 11px 8px;
		position: relative;

		.iconfont {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 16px;
			color: #4e5969;
			cursor: pointer;
		}
	}
	.schedule-before {
		position: absolute;
		top: 24px;
		left: 0;
		height: 24px;

		&::before {
			content: '';
			position: absolute;
			top: 5px;
			left: 0;
			bottom: 3px;
			width: 3px;
			background: var(--calendar-color);
			border-radius: 1px;
		}
	}
	.schedule-v1 {
		max-width: 100%;
		display: inline-flex;
		align-items: center;

		&--link {
			cursor: pointer;
		}
		span {
			flex: 1;
			font-family: 'PingFang SC';
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 150% */
		}
		.svg-icon {
			margin-left: 4px;
		}
	}
	.schedule-v2 {
		margin-top: 8px;

		& + .schedule-v2 {
			margin-top: 4px;
		}
	}
	.schedule-detail {
		margin-top: 16px;
	}
}
</style>
