<template>
	<div id="chart" ref="refChat"></div>
</template>

<script setup>
import { ref, defineExpose } from 'vue';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	VisualMapComponent,
	LegendComponent,
	BrushComponent,
	DataZoomComponent,
	TitleComponent
} from 'echarts/components';
import { CandlestickChart, LineChart, BarChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import { useStockVO, useCommonData } from '@/hooks';
import dayjs from 'dayjs';
import to from 'await-to-js';

echarts.use([
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	VisualMapComponent,
	LegendComponent,
	BrushComponent,
	DataZoomComponent,
	CandlestickChart,
	LineChart,
	BarChart,
	CanvasRenderer,
	UniversalTransition,
	TitleComponent
]);

const { getFullCode } = useStockVO();

function splitData(rawData) {
	let categoryData = []; // [时间]
	let values = [];
	for (let i = 0; i < rawData.length; i++) {
		const { tradingDay, avgPrice = 0 } = rawData[i];
		categoryData.push(dayjs(tradingDay).format('YYYY/MM/DD'));
		values.push(avgPrice);
	}
	return {
		categoryData: categoryData,
		values: values
	};
}
const queryQuote = async () => {
	const defaultTime = 1;
	const daytime = new Date();
	let endTradingDay = daytime.getTime();
	let startTradingDay = daytime.setFullYear(daytime.getFullYear() - 1);
	const [err, res] = await to(
		instance.get(enterprise.getKLineData(getFullCode.value, defaultTime), { startTradingDay, endTradingDay })
	);
	if (err) {
		console.log('😪 # queryQuote # err:', err);
		return [];
	}
	const arr = res?.data?.data?.reverse() || [];
	return arr;
};
const { getUpperThemeColor } = useCommonData();
let chatInstance = null;
const refChat = ref();
const refresh = async () => {
	if (chatInstance) {
		chatInstance.dispose();
		chatInstance.clear();
		chatInstance = null;
	}

	const data = await queryQuote();
	const { categoryData, values } = splitData(data);

	chatInstance = echarts.init(refChat.value);

	// 获取坐标轴刻度最大值
	const maxVal = Number(Math.max(...values)) * 1.01;
	// 获取坐标轴刻度最小值
	const minVal = Number(Math.min(...values)) * 0.98;
	// 计算坐标轴分割间隔
	let interval = (maxVal - minVal) / 5;

	// 指定图表的配置项和数据
	const option = {
		grid: {
			top: '0%',
			left: '0%',
			right: '0%',
			height: '100%'
		},
		xAxis: {
			type: 'category',
			data: categoryData,
			axisLine: {
				show: false
			},
			axisLabel: {
				show: false
			}
		},
		yAxis: {
			type: 'value',
			axisLine: {
				show: false
			},
			axisLabel: {
				show: false
			},
			splitNumber: 5,
			interval: interval,
			splitLine: {
				lineStyle: { color: '#F2F3F5', type: 'dashed' }
			},
			min: minVal,
			max: maxVal
		},
		series: [
			{
				type: 'line',
				symbol: 'none',
				sampling: 'lttb',
				itemStyle: {
					color: getUpperThemeColor.value
				},
				areaStyle: {
					color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						{
							offset: 0,
							color: getUpperThemeColor.value
						},
						{
							offset: 1,
							color: '#FFFFFF'
						}
					])
				},
				data: values
			}
		]
	};

	// 使用刚指定的配置项和数据显示图表。
	chatInstance.setOption(option);
};
defineExpose({
	refresh
});
</script>

<style lang="less" scoped>
#chart {
	width: 100%;
	height: 230px;
}
</style>
