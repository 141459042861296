let env, webirm_host;

export const isBuild = /(https|http):\/\/(irm-enterprise-pc\.comein\.cn)/i.test(location.href.toLowerCase());

// 环境区分
if (isBuild) {
	env = 'production';
	webirm_host = 'https://irm.comein.cn';
} else {
	env = 'development';
	webirm_host = 'https://testirm.comein.cn';
}

export const COMEIN_ENV = env;
export const WEBIRM_HOST = webirm_host;

// 历史访问导航缓存
export const CACHE_NAVIGATOR_HISTORY = 'APP_NAVIGATOR_HISTORY';
// 通用信息缓存
export const CACHE_COMMON_DATA = 'APP_COMMON_DATA';

export const WEBIRM_HOST_LIST = ['https://testirm.comein.cn', 'https://irm.comein.cn'];

export const CACHE_APP_VERSION_KEY = 'APP_VERSION';
export const APP_VERSION_NUMBER = '1.0.11';

// 埋点相关
export const TRACK_SESSION = 'APP_TRACK_SESSION';
export const TRACK_SEQUENCE = 'APP_TRACK_SEQUENCE';
export const TRACK_VERSION = '1.0.1';
