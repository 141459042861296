<template>
	<div class="schedule-data-item" @click="handleScheduleAction(item)">
		<div class="schedule-data-top">
			<div
				v-if="item.customsType"
				:style="insetCalendarColorVar(item.calendarThemeColor)"
				class="schedule-data-type"
			>
				{{ activityScheduleMap[item.customsType] }}
			</div>
			<div class="schedule-data-title text-overflow">{{ item.scheduleTheme }}</div>
			<div v-if="formatScheduleDate(item)" class="schedule-data-stime">{{ formatScheduleDate(item) }}</div>
		</div>
	</div>
</template>

<script setup>
import { useScheduleCore } from '@/hooks/calendar/useScheduleCore';
import { Message } from 'irm-ui';
import { previewFile } from '@/utils/api';
import moment from 'moment';

defineProps({
	item: {
		type: Object,
		default() {
			return {};
		}
	}
});

const { insetCalendarColorVar } = useScheduleCore();

const activityScheduleMap = {
	1: '投关活动',
	2: '公司公告',
	3: '新闻资讯'
};

const formatScheduleDate = date => {
	if (!date.scheduleStartTime) return null;

	let disVisibleHour = false;
	if (date.customsType === 1 && date.investmentActivityVO.sourceType === 1) {
		// 自主添加
		disVisibleHour = true;
	}
	if (date.customsType === 1 && date.investmentActivityType === 3) {
		disVisibleHour = true;
	}
	if (date.customsType === 2 || date.customsType === 3) {
		disVisibleHour = true;
	}
	if (disVisibleHour) {
		return null;
	}

	return `发布时间：${moment(date.scheduleStartTime).format('HH:mm')}`;
};

const handleScheduleAction = item => {
	const { type, customsType, investmentActivityType, investmentActivityVO, announcementVO, newsVO } = item;
	if (type === 2) {
		// 投关
		if (customsType === 1) {
			if (investmentActivityType === 3) {
				if (!investmentActivityVO.url) {
					Message('暂无活动文件');
					return;
				}
				previewFile(investmentActivityVO.url);
			} else {
				if (investmentActivityVO.descUrl) window.open(investmentActivityVO.descUrl);
			}
		}
		// 公告
		if (customsType === 2) {
			let realLink = announcementVO.comeinLink || announcementVO.sourceLink;
			if (realLink) previewFile(realLink);
		}
		// 新闻
		if (customsType === 3 && newsVO.url) {
			window.open(newsVO.url);
		}
	}
};
</script>

<style lang="less" scoped>
.schedule-data-item {
	padding: 12px;
	border-radius: 2px;
	transition: all 0.1s ease;
	cursor: pointer;

	&:hover {
		background: #f7f8fa;
	}
	.schedule-data-top {
		display: flex;
		align-items: center;
	}
	.schedule-data-type {
		flex: none;
		padding: 2px 4px;
		background: var(--calendar-fade-1-color);
		color: var(--calendar-color);
		font-family: 'PingFang SC';
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 14px; /* 140% */
		margin-right: 6px;
	}
	.schedule-data-title {
		flex: 1;
		width: 0;
		color: #1d2129;
		font-family: 'PingFang SC';
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px; /* 157.143% */
	}
	.schedule-data-stime {
		margin-left: 16px;
		color: #86909c;
		font-family: 'PingFang SC';
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px; /* 133.333% */
	}
}
</style>
