<template>
	<div
		class="header-banner-img"
		:class="{ 'header-banner-img--visible': visible }"
		:style="{ backgroundImage: `url(${src})` }"
	></div>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
			default: ''
		},
		visible: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="less" scoped>
.header-banner-img {
	width: 100%;
	height: 100%;

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	transition: opacity 0.6s ease-in-out;
	opacity: 0;

	&--visible {
		opacity: 1;
	}
}
</style>
