import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/home/HomeView.vue';
import LayoutView from '../views/layout/LayoutView.vue';
import store from '../store';

Vue.use(VueRouter);

const EnterpriseIRSingleTmpl = () => import(/*webpackChunkName: "index" */ '@/views/SingleTemplate.vue');

// 企业号单入口路由枚举
export const IR_ENTRY_TEMPLATE_MAP = [
	{
		path: 'stockDetail',
		name: 'stockDetail',
		title: '证券详情'
	},
	{
		path: 'quarterlyPmc',
		name: 'quarterlyPerformance',
		title: '季度业绩'
	},
	{
		path: 'investmentCol',
		name: 'investmentColumn',
		title: '投关栏目'
	},
	{
		path: 'announcement',
		name: 'announCement',
		title: '公司公告'
	},
	{
		path: 'questOn',
		name: 'question',
		title: '互动问答'
	},
	{
		path: 'news/list',
		name: 'news',
		title: '新闻资讯'
	},
	{
		path: 'news/detail',
		name: 'newsDetail',
		title: '新闻详情'
	},
	{
		path: 'research/list',
		name: 'researchList',
		title: '公司研报'
	},
	{
		path: 'research/detail',
		name: 'researchDetail',
		title: '研报详情'
	},
	{
		path: 'custom',
		name: 'custom',
		title: '自定义模块'
	},
	{
		path: 'customs_calendar',
		name: 'customsCalendar',
		title: '投关日历'
	},
];

const routes = [
	{
		path: '/',
		component: LayoutView,
		redirect: '/index',
		children: [
			{
				path: '/index',
				name: 'home',
				component: HomeView,
				meta: {
					title: '首页'
				}
			},

			// 单入口访问支持
			...IR_ENTRY_TEMPLATE_MAP.map(obj => {
				return {
					path: obj.path,
					name: obj.name,
					component: createCustomComponent(obj.name, EnterpriseIRSingleTmpl),
					meta: {
						title: obj.title
					}
				};
			})
		]
	},
	{
		path: '*',
		name: '404',
		component: () => import(/* webpackChunkName: "index" */ '@/views/ViewTo404.vue'),
		meta: {
			title: '抱歉，页面加载失败!',
			requireAuth: false
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	}
});

/**
 * 将指定组件设置自定义名称
 *
 * @param {String} name 组件自定义名称
 * @param {Component | Promise<Component>} component
 * @return {Component}
 */
export function createCustomComponent(name, component) {
	return {
		name,
		data() {
			return { component: null };
		},
		async created() {
			if (component instanceof Promise) {
				try {
					const module = await component;
					this.component = module?.default;
				} catch (error) {
					console.error(`can not resolve component ${name}, error:`, error);
				}

				return;
			}
			this.component = component;
		},
		render(h) {
			return this.component ? h(this.component) : null;
		}
	};
}

router.beforeEach((to, from, next) => {
	if (to.fullPath.includes('%20')) {
		console.log('--------- 识别到链接中的空格符号，触发重定向 ---------');
		const newPath = to.fullPath.replace(/%20/g, '');
		// 重定向到新路径，并使用replace防止向历史记录添加新条目
		return next({ path: newPath, replace: true });
	}

	if (
		store.state.storeKeepAlive.keepAliveRules[from.name] &&
		store.state.storeKeepAlive.keepAliveRules[from.name].includes(to.name)
	) {
		if (!store.state.storeKeepAlive.cachePages.includes(from.name)) {
			store.commit('storeKeepAlive/addInclude', from.name);
		}
	} else {
		store.commit('storeKeepAlive/removeInclude', from.name);
	}

	next();
});

export default router;
