<template>
	<CLoadingBox :loading="loading" :empty="empty">
		<div class="wrapper-box">
			<RowInfoLink
				v-for="(item, index) in listData"
				:key="index"
				:sort="index + 1"
				:item="item"
				:border="index != listData.length - 1"
				@click="goDetail(item)"
			>
				<div slot="more" class="box-more">
					<span>详情</span>
					<i class="iconfont icon-flip-right"></i>
				</div>
			</RowInfoLink>
		</div>
	</CLoadingBox>
</template>

<script setup>
import RowInfoLink from '@/views/home/components/RowInfoLink.vue';
import { computed, defineExpose, useAttrs } from 'vue';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import dayjs from 'dayjs';
import { handleDetail } from '@/utils/fileDetail';
import { useDataState, useCommonData } from '@/hooks';

const goDetail = item => {
	handleDetail(item, 'custom', { moduleId: attrs.moduleId });
};

const attrs = useAttrs();
const { data, loading, empty, setData, setLoading } = useDataState();
const listData = computed(() =>
	(data.value || []).slice(0, 3).map(item => {
		item.title = item.subject;
		// 转一遍时间戳
		item.time = dayjs(item.contentTime).valueOf();
		return item;
	})
);

const { getStoreId } = useCommonData();
const fetchList = async () => {
	setLoading(true);
	return instance
		.get(enterprise.getCustomModuleContent(getStoreId.value), {
			moduleId: attrs.moduleId,
			pageNum: 20,
			pageStart: 0
		})
		.then(response => {
			setData(response.data?.rows);
			setLoading(false);
		})
		.catch(() => {
			setData([]);
			setLoading(false);
		});
};
const refreshComponent = () => {
	fetchList();
};

defineExpose({
	refreshComponent
});
</script>

<style lang="less" scoped>
.wrapper-box {
	.box-more {
		display: flex;
		align-items: center;
		cursor: pointer;

		span {
			color: #86909c;
			text-align: right;
			font-family: 'PingFang SC';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 114.286% */
		}
		.iconfont {
			font-size: 16px;
			color: #86909c;
		}
	}
}
</style>
