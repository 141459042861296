<template>
	<div class="banner-card-wrapper">
		<div class="banner">
			<el-image
				v-if="item.logo43"
				style="width: 100%; height: 100%"
				:src="item.logo43"
				fit="cover"
				lazy
			></el-image>
			<EmptyCover v-else :text="emptyText" />
			<div v-if="item.isTop" class="is-top">置顶</div>
		</div>
		<div class="content mt-12 text-overflow2">{{ item.title }}</div>
		<div class="date mt-8">{{ formatDateFn(item.stime) }}</div>
	</div>
</template>

<script setup>
import dayjs from 'dayjs';

defineProps({
	item: {
		type: Object,
		default() {
			return {};
		}
	},
	emptyText: {
		type: String,
		default: ''
	}
});

const formatDateFn = date => {
	return dayjs(date).format('YYYY-MM-DD');
};
</script>

<style lang="less" scoped>
.banner-card-wrapper {
	width: 253px;
	.banner {
		height: 189px;
		cursor: pointer;
		position: relative;

		/deep/.el-image__inner {
			transition: all 0.18s ease-in-out;
		}
		&:hover {
			/deep/.el-image__inner {
				transform: scale(1.08);
			}
		}
		.is-top {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0 10px;
			height: 20px;
			line-height: 20px;
			font-family: PingFang SC;
			font-size: 12px;
			font-weight: 400;
			color: #ffffff;
			border-radius: 0 0 2px 2px;
			background: #f23d3d;
		}
	}
	.content {
		font-family: PingFang SC;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		color: #1d2129;
		// height: 48px;
	}
	.date {
		font-family: PingFang SC;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		color: #4e5969;
	}
}
</style>
