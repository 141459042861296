<template>
	<div class="module-header">
		<slot v-if="slots.default"></slot>
		<div v-else class="header-label">{{ label }}</div>
		<div class="header-to" @click="handleMore">
			<span>查看更多</span>
			<i class="iconfont icon-flip-right"></i>
		</div>
	</div>
</template>

<script setup>
import { useSlots } from 'vue';

const slots = useSlots();
defineProps({
	label: String
});

const emits = defineEmits(['toMore']);
const handleMore = () => {
	emits('toMore');
};
</script>

<style lang="less" scoped>
.module-header {
	height: 42px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.header-label {
		color: #1d2129;
		font-family: 'PingFang SC';
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%; /* 42px */
	}
	.header-to {
		display: flex;
		align-items: center;
		cursor: pointer;

		span {
			color: #4e5969;
			text-align: right;
			font-family: 'PingFang SC';
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 114.286% */
		}
		.iconfont {
			font-size: 16px;
			color: #4e5969;
		}
	}
}
</style>
