<template>
	<button
		v-on="$listeners"
		class="cell-todo-row-item button-clear"
		:class="{
			'cell-todo-row-more': data.type == 'non',
			'cell-todo-row--un-shadow': !shadow
		}"
		:style="insetCalendarColorVar(data.calendarThemeColor)"
	>
		<template v-if="data.type == 'non'">
			<div class="cell-todo-row-value">还有 {{ data.value }} 项</div>
			<SvgIcons name="right-light" />
		</template>
		<div v-else class="cell-todo-row-value text-overflow">{{ formatScheduleRow }}</div>
	</button>
</template>

<script setup>
import { computed } from 'vue';
import moment from 'moment';
import { useScheduleCore } from '@/hooks/calendar/useScheduleCore';
import SvgIcons from '@/components/SvgIcons.vue';

const props = defineProps({
	data: {
		type: Object,
		default: () => {}
	},
	shadow: {
		type: Boolean,
		default: true
	}
});
const checkScheduleThemeName = computed(() => {
	const { calendarVO, scheduleTheme } = props.data;
	const { isOwn, innerPermission } = calendarVO;
	// true:自有 false:订阅
	if (isOwn) return scheduleTheme || '-';
	else {
		// 如他人日程查看权限为「游客」，展示为忙碌
		return innerPermission === 2 ? '忙碌' : scheduleTheme || '-';
	}
});
const formatDate = date => {
	if (!date.scheduleStartTime) return '-';

	let disVisibleHour = false;
	if (date.customsType === 1 && date.investmentActivityVO.sourceType === 1) {
		// 自主添加
		disVisibleHour = true;
	}
	if (date.customsType === 1 && date.investmentActivityType === 3) {
		disVisibleHour = true;
	}
	if (date.customsType === 2 || date.customsType === 3) {
		disVisibleHour = true;
	}
	if (disVisibleHour) {
		return '';
	}

	return moment(date.scheduleStartTime).format('HH:mm');
};
const formatScheduleRow = computed(() => {
	const { scheduleStartTime, scheduleEndTime } = props.data;
	const isSameDay = moment(scheduleStartTime).isSame(scheduleEndTime, 'day');
	if (!isSameDay) return checkScheduleThemeName.value; // 跨天的日程 不直接展示时间
	return `${formatDate(props.data)} ${checkScheduleThemeName.value}`;
});
const { insetCalendarColorVar } = useScheduleCore();
</script>

<style lang="less" scoped>
.button-clear {
	appearance: none; /* 移除某些浏览器的默认样式 */
	padding: 0; /* 移除内边距 */
	border: none; /* 移除边框 */
	background-color: transparent; /* 设置背景色为透明 */
	font: inherit; /* 使用继承的字体样式 */
	cursor: default; /* 使用默认光标样式 */
	text-align: left; /* 按需设置文本对齐方式 */
	-webkit-appearance: none; /* 移除WebKit内核浏览器的默认样式 */
	display: block;
	width: 100%;

	&:hover,
	&:focus,
	&:active {
		outline: none; /* 移除焦点轮廓线 */
	}
}
.cell-todo-row-item {
	padding: 4px;
	color: var(--emphasis-color);
	border-radius: 2px;
	background: #fff;
	position: relative;
	cursor: pointer;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
	margin-bottom: 4px;
	transition: all 0.1s ease;

	&:focus {
		background: var(--theme-color-gradient-003) !important;
		color: var(--theme-color);
	}
	&.cell-todo-row--un-shadow {
		box-shadow: none;
		margin-bottom: 8px;
		background: transparent;
		transition: background 0.17s ease-in-out;

		&:hover {
			background: var(--theme-color-gradient-003);
		}
	}
	&.cell-todo-row--active {
		background: var(--theme-color-gradient-003);
	}
	&:last-child {
		margin-bottom: 0;
	}

	&::before {
		content: '';
		position: absolute;
		top: 5px;
		left: 4px;
		bottom: 5px;
		width: 3px;
		background: var(--calendar-color);
		border-radius: 1px;
	}
	&.cell-todo-row-more {
		display: flex;
		justify-content: center;
		align-items: center;

		&::before {
			display: none;
		}
		&:hover {
			color: var(--theme-color);

			.svg-icon {
				color: var(--theme-color) !important;
			}
		}
		.cell-todo-row-value {
			margin-left: 0;
		}
		.svg-icon {
			margin-left: 4px;
			font-size: 16px;
			color: #1d2129 !important;
		}
	}
	.cell-todo-row-value {
		margin-left: 7px;
		font-family: 'PingFang SC';
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px; /* 133.333% */
	}
}
</style>
