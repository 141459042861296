<template>
	<CLoadingBox :loading="loading" :empty="empty">
		<div class="qna-wrapper">
			<RowInfoQnA
				v-for="(item, index) in qnaList"
				:key="index"
				:border="index != qnaList.length - 1"
				:item="item"
			></RowInfoQnA>
		</div>
	</CLoadingBox>
</template>

<script setup>
import RowInfoQnA from '@/views/home/components/RowInfoQnA.vue';
import { defineExpose, computed } from 'vue';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import { useStockVO, useDataState, useCommonData } from '@/hooks';

const { getFullCode } = useStockVO();
const { data, loading, empty, setData, setLoading } = useDataState();
const qnaList = computed(() => (data.value || []).slice(0, 3));

const { getStoreId } = useCommonData();
const fetchList = async () => {
	setLoading(true);
	return instance
		.post(enterprise.getAdvisoryList, {
			pagenum: 20,
			pagestart: 0,
			fullCode: getFullCode.value,
			storeId: getStoreId.value
		})
		.then(response => {
			setData(response.data?.rows);
			setLoading(false);
		})
		.catch(() => {
			setData([]);
			setLoading(false);
		});
};
const refreshComponent = () => {
	fetchList();
};

defineExpose({
	refreshComponent
});
</script>

<style lang="less" scoped></style>
