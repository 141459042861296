<template>
	<div v-on="$listeners" class="link-row" :class="{ 'link-row--border': border }">
		<div class="link-sort">{{ sort }}</div>
		<div class="link-title text-overflow">{{ item.title }}</div>
		<div class="mt-16 link-footer">
			<div class="link-date">{{ formatDateFn(item.time) }}</div>
			<slot name="more" :item="item"></slot>
		</div>
	</div>
</template>

<script setup>
import dayjs from 'dayjs';

defineProps({
	border: {
		type: Boolean,
		default: false
	},
	sort: {
		type: Number,
		default: 1
	},
	item: {
		type: Object,
		default() {
			return {};
		}
	}
});

const formatDateFn = date => {
	return dayjs(date).format('YYYY-MM-DD');
};
</script>

<style lang="less" scoped>
.link {
	&-row {
		position: relative;
		padding-left: 36px;
		padding-bottom: 12px;
		font-size: 0;
		cursor: pointer;

		& + .link-row {
			margin-top: 32px;
		}
		&--border {
			border-bottom: 0.5px solid #e5e6eb;
		}

		&:hover {
			&.link-row--border {
				border-bottom: 0.5px solid var(--theme-color);
			}
			.link-title {
				color: var(--theme-color);
			}
		}
	}
	&-sort {
		position: absolute;
		top: 0;
		left: 0;
		width: 22px;
		height: 22px;

		text-align: center;
		color: #fff;
		font-family: Barlow;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 22px;

		border-radius: 2px;
		background: var(--theme-color);
	}
	&-title {
		color: #1d2129;
		font-family: 'PingFang SC';
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 140%; /* 22.4px */
		max-width: 100%;
		display: inline-block;
		word-break: break-all;
	}
	&-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&-date {
		color: #4e5969;
		font-family: 'PingFang SC';
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 23px; /* 114.286% */
	}
}
</style>
