import axios from 'axios';
import { common } from './interface';
import { getCookie } from '@/utils/cookie';

const defaultTimeout = 15000;
const instance = axios.create({
	timeout: defaultTimeout,
	headers: {
		'Content-Type': 'application/json;charset=utf-8'
	},

	// `transformRequest` 允许在向服务器发送前，修改请求数据
	// 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
	// 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream（node.js）
	transformRequest: [
		function (data) {
			// 对 data 进行任意转换处理
			if (Object.prototype.toString.call(data) === '[object FormData]') {
				return data;
			}

			return JSON.stringify(data);
		}
	]
});

instance.interceptors.request.use(
	config => {
		config.headers['os'] = 'web';
		config.headers['c'] = 'pc';
		config.headers['webenv'] = 'irm';
		config.headers['s'] = '';
		config.headers['browse'] = window.navigator.appName;
		config.headers['ua'] = window.navigator.userAgent;
		config.headers['b'] = '3.0.3';
		config.headers['uc'] = 'irm-enterprise';
		if (getCookie('release')) {
			config.headers['release'] = getCookie('release');
		}
		if (getCookie('symbol')) {
			config.headers['symbol'] = getCookie('symbol');
		}

		// 识别到请求地址中的 undefined，跳出请求
		if (config.url.includes('undefined')) {
			return Promise.reject(false);
		}

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	response => {
		if (response && response.status == 500) {
			const msg = response.data.msg || '连接服务器失败，请稍后重试';
			return Promise.reject(msg);
		}
		return response;
	},
	error => {
		return Promise.reject(error);
	}
);

// 重新定义请求方法
instance.get = function (url, params = {}, headers = {}) {
	const splicePHP = spliceQueryParamsInPHP(url);
	if (JSON.stringify(splicePHP) != '{}') {
		headers = Object.assign(headers, spliceQueryParamsInPHP(url));
		url = common.phpUrl;
	}

	return instance.request({
		method: 'get',
		url,
		params,
		headers
	});
};
instance.post = function (url, data = {}, headers = {}) {
	let sendData = data;
	let _timeout = defaultTimeout;

	// 文件上传
	if (headers.bodyType == 'file') {
		headers['Content-Type'] = 'multipart/form-data';
		var formData = new FormData();
		Object.keys(data).map(index => {
			formData.append(index, data[index]);
		});
		sendData = formData;
		_timeout = 1800000; //超时时间为半小时
	}

	const splicePHP = spliceQueryParamsInPHP(url);
	if (JSON.stringify(splicePHP) != '{}') {
		headers = Object.assign(headers, spliceQueryParamsInPHP(url));
		url = common.phpUrl;
	}

	return instance.request({
		method: 'post',
		url,
		data: sendData,
		headers,
		timeout: _timeout
	});
};
instance.delete = function (url, params = {}, headers = {}) {
	return instance.request({
		method: 'delete',
		url,
		params,
		headers
	});
};
instance.put = function (url, data = {}, headers = {}) {
	return instance.request({
		method: 'put',
		url,
		data,
		headers
	});
};

const spliceQueryParamsInPHP = url => {
	let arr = url.split('_');

	if (arr.length != 3 || url.includes('/irstore')) {
		return {};
	}

	return {
		app: arr[0],
		mod: arr[1],
		act: arr[2]
	};
};

export default instance;
