var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper-box",class:{ 'wrapper-box--half': _vm.col == '50%' }},[_c(_setup.CCalendarV2,{staticClass:"calendar-wrap",attrs:{"value":_setup.dateValue,"scheduleData":_setup.scheduleData,"noToday":_setup.latestSchedule.length > 0,"useCardStyle":""},on:{"update:value":function($event){_setup.dateValue=$event},"cell-click":_setup.handleCellClick}}),_c('div',{staticClass:"calendar-content"},[(_vm.col == '50%')?_c('div',{staticClass:"calendar-half-wrap"},[(_setup.latestSchedule.length)?_vm._l((_setup.latestSchedule),function(item,index){return _c('div',{key:index,staticClass:"calendar-half-row"},[_c('div',{staticClass:"calendar-half-date"},[_vm._v(_vm._s(_setup.formatHalfDate(item.date)))]),_vm._l((item.scheduleList),function(item2,index2){return _c(_setup.CalendarScheduleRow,{key:index2,ref:el => _setup.getScheduleRef(el, item2.rowId),refInFor:true,attrs:{"data":item2},on:{"click":function($event){$event.stopPropagation();return _setup.handleShowPopover(item2.rowId, {
								date: item2.date,
								data: item2.data,
								item: item2
							})}}})})],2)}):[_c('div',{staticClass:"calendar-half-date"},[_vm._v(_vm._s(_setup.formatHalfDate(_setup.dateValue)))]),(!_setup.generateFullViewCellContent.length)?_c('Empty'):_vm._l((_setup.generateFullViewCellContent),function(item,index){return _c(_setup.CalendarScheduleRow,{key:index,ref:el => _setup.getScheduleRef(el, item.rowId),refInFor:true,attrs:{"data":item},on:{"click":function($event){$event.stopPropagation();return _setup.handleShowPopover(item.rowId, {
								date: item.date,
								data: item.data,
								item
							})}}})})]],2):[(_setup.latestSchedule.length)?_vm._l((_setup.latestSchedule),function(item,index){return _c('div',{key:index,staticClass:"calendar-half-row"},[_c('div',{staticClass:"calendar-data-date"},[_vm._v(_vm._s(_setup.formatCalendarDate(item.date)))]),_c('div',{staticClass:"mt-6"},_vm._l((item.scheduleList),function(item2,index2){return _c(_setup.CalendarScheduleCard,{key:index2,attrs:{"item":item2}})}),1)])}):[_c('div',{staticClass:"calendar-data-date"},[_vm._v(_vm._s(_setup.formatCalendarDate(_setup.dateValue)))]),_c('div',{staticClass:"calendar-data-scroll mt-6"},[_c('el-scrollbar',[(!_setup.scheduleByCurDay.length)?_c('Empty'):_vm._l((_setup.scheduleByCurDay),function(item,index){return _c(_setup.CalendarScheduleCard,{key:index,attrs:{"item":item}})})],2)],1)]]],2),(_setup.showPopover)?_c('el-popover',{ref:"popoverRef",attrs:{"placement":"right","width":"266","trigger":"click","popper-class":"schedule-popper","visible-arrow":false,"reference":_setup.referenceNode}},[_c(_setup.SchedulePopup,{attrs:{"cellDate":_setup.currScheduleContent.date,"cellData":_setup.currScheduleContent.data,"data":_setup.currScheduleContent.item}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }