import { computed } from 'vue';
import { useStore } from '@/utils/api';

function isEnglish(str) {
	// 使用正则表达式进行匹配，'^'表示开始，'$'表示结束，'i'表示不区分大小写
	// '[a-z]'表示所有的英文字母
	const reg = /^[a-z]+$/i;
	return reg.test(str);
}

// 当前公司旗下的股票代码信息
export const useStockVO = () => {
	const store = useStore();

	const getStockInfoVOS = computed(() => store.getters.getCommonData.stockInfoVOS || []);
	const selectIdxAtStockInfoVOS = computed(() => store.state.stockSelectIndex);
	const getTargetStockVO = computed(() => getStockInfoVOS.value[selectIdxAtStockInfoVOS.value] || {});
	const isHKStock = computed(() => {
		const { market } = getTargetStockVO.value;
		if (!market) return false;
		return market.toLocaleUpperCase() == 'HK';
	});
	// 上交所
	const isSHStock = computed(() => {
		const { market } = getTargetStockVO.value;
		if (!market) return false;
		return market.toLocaleUpperCase() == 'SH';
	});
	// 深交所
	const isSZStock = computed(() => {
		const { market } = getTargetStockVO.value;
		if (!market) return false;
		return market.toLocaleUpperCase() == 'SZ';
	});
	const isUSStock = computed(() => {
		// 美股和港股的代码都可能是纯英文的，但是我们在这里通过检查不是港股的条件，来确定一个股票是否是美股。
		const isEnCode = isEnglish(getFullCode.value);
		return isEnCode && !isHKStock.value;
	});
	const getFullCode = computed(() => {
		const targetVO = getTargetStockVO.value;
		return `${targetVO.market}${targetVO.code}`;
	});
	const getStockInfoVOSAndUpperCase = computed(() => {
		return (getStockInfoVOS.value || []).map(item => {
			return {
				label: `${item.market.toLocaleUpperCase()} - ${item.code}`,
				value: `${item.market}${item.code}`
			};
		});
	});
	const changeStockVOSelectIndex = index => {
		store.commit('setStockSelectIndex', index);
	};

	return {
		isHKStock,
		isSHStock,
		isSZStock,
		isUSStock,
		getFullCode,
		getStockInfoVOS,
		getTargetStockVO,
		getStockInfoVOSAndUpperCase,
		selectIdxAtStockInfoVOS,
		changeStockVOSelectIndex
	};
};
