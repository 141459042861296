import { debounce } from '@/utils/api';
import moment from 'moment';
import { ref } from 'vue';

// 管理日程渲染相关缓存与更新逻辑
export const useScheduleCache = (options = {}) => {
	// 提取配置对象中的 `onRenderUpdate` 回调函数，用于在视图需要刷新时触发外部更新操作。
	const { onRenderUpdate } = options;

	// 存储已缓存日程数据的月份键（YYYYMM 格式）。
	const cachedScheduleMonthKeys = ref([]);

	const resetCachedScheduleMonthKeys = val => {
		cachedScheduleMonthKeys.value = val || [];
	};

	// 当 `date` 变化且对应的月份键不在缓存中时，触发视图刷新逻辑
	const updateScheduleRender = debounce(
		function (date) {
			const currDateKey = moment(date).format('YYYYMM');
			const includeCacheKey = cachedScheduleMonthKeys.value.includes(currDateKey);
			if (!includeCacheKey) {
				// 在视图需要刷新时触发外部更新操作。
				onRenderUpdate?.();
			}
		},
		180,
		false
	);

	return {
		cachedScheduleMonthKeys,
		updateScheduleRender,
		resetCachedScheduleMonthKeys
	};
};
