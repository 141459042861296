import moment from 'moment';

export const defaultColor = '#3964E5';
export const calendarColorMap = [
	'#3964E5',
	'#73DCFF',
	'#9673FF',
	'#FF7373',
	'#FF9673',
	'#FFB973',
	'#FFDC73',
	'#E5E567',
	'#C6E567',
	'#67E587'
];
export const calendarFade01ColorMap = {
	'#3964E5': {
		fade01Color: 'rgba(57, 100, 229, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_1.png'
	},
	'#73DCFF': {
		fade01Color: 'rgba(115, 220, 255, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_2.png'
	},
	'#9673FF': {
		fade01Color: 'rgba(150, 115, 255, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_3.png'
	},
	'#FF7373': {
		fade01Color: 'rgba(255, 115, 115, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_4.png'
	},
	'#FF9673': {
		fade01Color: 'rgba(255, 150, 115, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_5.png'
	},
	'#FFB973': {
		fade01Color: 'rgba(255, 185, 115, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_6.png'
	},
	'#FFDC73': {
		fade01Color: 'rgba(255, 220, 115, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_7.png'
	},
	'#E5E567': {
		fade01Color: 'rgba(229, 229, 103, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_8.png'
	},
	'#C6E567': {
		fade01Color: 'rgba(198, 229, 103, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_9.png'
	},
	'#67E587': {
		fade01Color: 'rgba(103, 229, 135, 0.1)',
		fadeImage: 'https://comein-image.oss-cn-hangzhou.aliyuncs.com/web/irm/web/calendar/fade_10.png'
	}
};

export const useScheduleCore = () => {
	// 日历颜色
	const insetCalendarColorVar = color => {
		const safeColorVal = color && calendarColorMap.includes(color) ? color : defaultColor;
		const safeFade01ColorVal = calendarFade01ColorMap[safeColorVal].fade01Color;
		return {
			'--calendar-color': safeColorVal,
			'--calendar-fade-1-color': safeFade01ColorVal
		};
	};
	const getCalendarColorImg = color => {
		const safeColorVal = color && calendarColorMap.includes(color) ? color : defaultColor;
		const safeFade01Image = calendarFade01ColorMap[safeColorVal].fadeImage;
		return safeFade01Image;
	};

	/**
	 * 计算目标日期在指定时间单位下对应的时间范围
	 */
	const calculateDateRange = (date, unit = 'day') => {
		return [moment(date).startOf(unit).valueOf(), moment(date).endOf(unit).valueOf()];
	};
	/**
	 * 根据指定的时间范围过滤数据
	 * @param {Array} timeRange - 时间范围，格式为 [开始时间, 结束时间]
	 * @param {Array} data - 待过滤的数据数组
	 * @param {Boolean} isHour - 是否按照小时级别过滤
	 * @returns {Array|Boolean} - 返回符合条件的数据数组，如果没有符合条件的数据则返回false
	 */
	const filterDataByTimeRange = (timeRange, data, isHour = false) => {
		// 用于存储符合条件的数据项
		const resultMap = new Map();
		// 过滤出满足指定日期范围内的数据
		(data || []).forEach(d => {
			// TODO format propName option: stime/etime/color...
			const { scheduleStartTime, scheduleEndTime } = d;

			// 判断当前元素的时间范围是否与目标range有重叠（日期级别）
			const isDateRangeOverlap = scheduleStartTime <= timeRange[1] && scheduleEndTime >= timeRange[0];

			let isTimeRangeOverlap = isDateRangeOverlap;
			// 如果存在时刻维度 并且之前判断的时间范围有重叠，进一步检查当前数据的开始时间的时分 是否落在目标范围的时分内
			if (isHour && isTimeRangeOverlap) {
				const startTimeHour = moment(scheduleStartTime).format('HH:mm');
				const rangeStartHour = moment(timeRange[0]).format('HH:mm');
				const rangeEndHour = moment(timeRange[1]).format('HH:mm');
				isTimeRangeOverlap = startTimeHour >= rangeStartHour && startTimeHour <= rangeEndHour;
			}

			if (isTimeRangeOverlap) {
				if (resultMap.get(timeRange)) {
					// 追加到已有数组中，并更新rangeMap
					resultMap.set(timeRange, [...resultMap.get(timeRange), d]);
				} else {
					resultMap.set(timeRange, [d]);
				}
			}
		});
		if (resultMap.get(timeRange)) {
			return resultMap.get(timeRange);
		}
		return false;
	};

	return {
		insetCalendarColorVar,
		getCalendarColorImg,
		calculateDateRange,
		filterDataByTimeRange
	};
};
