<template>
	<div class="common-tab" :class="['common-tab--' + type]">
		<div
			v-for="(item, index) in option"
			:key="index"
			class="common-tab-item"
			:class="{ 'common-tab-item--active': item.value == innerValue }"
			@click="changeTab(item, index)"
		>
			{{ item.label }}
		</div>
	</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	value: {
		// eslint-disable-next-line vue/require-prop-type-constructor
		type: String | Number,
		default: ''
	},
	type: {
		type: String,
		default: 'normal' // 默认 normal | 矩形 rect | 内联 inner | ...
	},
	option: {
		type: Array,
		default: () => [] // { label, value }
	}
});
const emits = defineEmits(['change', 'update:value']);
const innerValue = computed({
	get: () => props.value,
	set: val => {
		emits('update:value', val);
	}
});
const changeTab = (item, idx) => {
	if (innerValue.value == item.value) return;
	innerValue.value = item.value;
	emits('change', {
		index: idx,
		value: item.value,
		data: props.option[idx]
	});
};
</script>

<style lang="less" scoped>
.common-tab {
	display: inline-flex;

	&-item {
		padding: 0 12px;
		font-family: Barlow;
		font-style: normal;
		background: transparent;
		cursor: pointer;

		&--active {
			font-weight: 500;
		}
	}

	// 区分不同类型的样式
	&--normal {
		.common-tab-item {
			padding: 0 4px;
			height: 55px;
			font-size: 24px;
			color: #86909c;
			position: relative;

			& + .common-tab-item {
				margin-left: 60px;
			}
			&--active {
				color: #1d2129;

				&::after {
					content: '';
					position: absolute;
					left: 8px;
					right: 8px;
					bottom: 0;
					height: 4px;
					background: var(--theme-color);
				}
			}
		}
	}
	&--rect {
		.common-tab-item {
			height: 24px;
			line-height: 22px;
			color: #4e5969;
			border: 1px solid #e5e6eb;
			font-size: 15px;

			& + .common-tab-item {
				margin-left: 8px;
			}
			&--active {
				color: #fff;
				background: var(--theme-color);
				border: 1px solid var(--theme-color);
			}
		}
	}
	&--inner {
		padding: 4px;
		border-radius: 2px;
		background: #f2f3f5;

		.common-tab-item {
			height: 32px;
			line-height: 32px;
			color: #86909c;
			font-size: 16px;

			&--active {
				color: var(--theme-color);
				background: #fff;
			}
		}
	}
}
</style>
