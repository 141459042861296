// 阿里云埋点

import Router from '@/router/index';
import { getTrackConfigById } from './tree';
import { isBuild } from '@/const/host';
import { deepRemoveProperty, generateRandomString } from '@/utils/api';
import SlsTracker from '@aliyun-sls/web-track-browser';
import { CACHE_NAVIGATOR_HISTORY, TRACK_SESSION, TRACK_SEQUENCE, TRACK_VERSION } from '@/const/host';

let host, project, logstore;
if (isBuild) {
	host = 'cn-hangzhou.log.aliyuncs.com'; // 日志服务的域名
	project = 'irm-pro'; // 项目名
	logstore = 'irm-external-pro'; // 日志库名
} else {
	host = 'cn-hangzhou.log.aliyuncs.com'; // 日志服务的域名
	project = 'irm'; // 项目名
	logstore = 'irm-external'; // 日志库名
}

// 企业号埋点实例
const opts = {
	host: `${host}`, // 所在区域的host
	project: `${project}`, // project名称
	logstore: `${logstore}`, // logstore名称
	time: 10, // 发送日志的时间间隔，默认是10秒。
	count: 10, // 发送日志的数量大小，默认是10条。
	topic: 'topic', // 自定义日志主题。
	source: 'irstore' // 自定义日志来源。
};

let tracker = null;

export const trackerInit = () => {
	if (tracker) return;
	tracker = new SlsTracker(opts);
};

// 发送日志提供了4种方式  send 延时单条  sendImmediate 立即单条  sendBatchLogs 延时批量  sendBatchLogsImmediate 立即批量
export const trackerSend = params => {
	if (!tracker) return;
	tracker.sendImmediate({
		...params
	}); // 发送日志
};
export const trackerBatchSend = () => {
	if (!tracker) return;
	//
};

function removeQueryString(url) {
	// 使用正则表达式匹配URL中的查询字符串起始位置（即问号“?”）
	const queryStringIndex = url.indexOf('?');

	// 如果找到查询字符串，则返回从URL开头到查询字符串起始位置的部分
	// 否则，返回原始URL（无查询字符串可移除）
	return queryStringIndex !== -1 ? url.slice(0, queryStringIndex) : url;
}
const mergeGlobalParams = (params = {}) => {
	let _session = sessionStorage.getItem(TRACK_SESSION),
		_sequence = sessionStorage.getItem(TRACK_SEQUENCE);
	if (!_session) {
		_session = generateRandomString(36);
		sessionStorage.setItem(TRACK_SESSION, _session);
	}
	if (!_sequence) {
		_sequence = 0;
		sessionStorage.setItem(TRACK_SEQUENCE, _sequence);
	}
	sessionStorage.setItem(TRACK_SEQUENCE, Number(_sequence) + 1);

	let prevPage;
	let history = sessionStorage.getItem(CACHE_NAVIGATOR_HISTORY);
	history = history ? JSON.parse(history) : [];
	if (history.length > 1) {
		prevPage = history[history.length - 2].path;
	}

	const globalParams = {
		env: isBuild ? 'prod' : 'dev',
		timestamp: Date.now(),
		session: _session,
		sequence: _sequence,
		fullPath: Router.currentRoute.fullPath,
		prevPage: prevPage,
		client: 'web', // 用户端，网页端为web，小程序为miniapp
		agent: 'pc',
		source: 'irm-enterprise-pc',
		userAgent: navigator.userAgent, // 兼容签到页这种直接扫码进入的设备记录
		version: TRACK_VERSION
	};
	const finallyParams = {
		...globalParams,
		...params
	};
	if (finallyParams.fullPath) {
		// 避免埋点信息过长 过滤掉路径参数
		finallyParams.fullPath = removeQueryString(finallyParams.fullPath);
	}
	if (finallyParams.prevPage) {
		// 避免埋点信息过长 过滤掉路径参数
		finallyParams.prevPage = removeQueryString(finallyParams.prevPage);
	}
	return deepRemoveProperty(finallyParams);
};

const EVENT_TYPE_MAP = {
	点击: 'CLICK',
	数据导入: 'CLICK-IMPORT',
	数据导出: 'CLICK-EXPORT',
	新增: 'CLICK-ADD',
	通知发送: 'CLICK-NOTE',
	编辑: 'CLICK-EDIT',
	删除: 'CLICK-DEL',
	登陆: 'CLICK-LOGIN',
	退出: 'CLICK-LOGOUT',
	筛选查询: 'CLICK-FILTER'
};
const transferCNEventType = value => {
	const nonValue = !value || !EVENT_TYPE_MAP[value];
	// 默认给 CLICK
	return nonValue ? 'CLICK' : EVENT_TYPE_MAP[value];
};
// 点击
export const executeTrackerByClick = data => {
	trackerSend(
		mergeGlobalParams({
			...data,
			event: transferCNEventType(data.event)
		})
	);
};
// 浏览
export const executeTrackerByView = data => {
	trackerSend(
		mergeGlobalParams({
			...data,
			event: 'VIEW'
		})
	);
};
// 曝光
export const executeTrackerByExp = data => {
	trackerSend(
		mergeGlobalParams({
			...data,
			event: 'EXP'
		})
	);
};

// 确保每个埋点信息都通过埋点编号去匹配
export const IRSTORE_T_VIEW = (nid, otherConfig = {}) => {
	const basicConfig = getTrackConfigById(nid);
	return executeTrackerByView({
		...basicConfig,
		...otherConfig
	});
};
export const IRSTORE_T_CLICK = (nid, otherConfig = {}) => {
	const basicConfig = getTrackConfigById(nid);
	return executeTrackerByClick({
		...basicConfig,
		...otherConfig
	});
};
