import { render, staticRenderFns } from "./calendar-schedule-card.vue?vue&type=template&id=b8f9a01c&scoped=true"
import script from "./calendar-schedule-card.vue?vue&type=script&setup=true&lang=js"
export * from "./calendar-schedule-card.vue?vue&type=script&setup=true&lang=js"
import style0 from "./calendar-schedule-card.vue?vue&type=style&index=0&id=b8f9a01c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8f9a01c",
  null
  
)

export default component.exports