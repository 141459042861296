<template>
	<div class="common-search">
		<el-input :placeholder="placeholder" v-model="innerValue" class="search-input" @input="inputSearch" clearable>
			<i slot="suffix" class="iconfont icon-icon_sousuo_16_sousuokuang1" v-if="!innerValue"></i>
		</el-input>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { debounce } from '@/utils/api';

const props = defineProps({
	value: {
		type: String
	},
	placeholder: {
		type: String,
		default: '请输入关键词搜索'
	}
});
const emits = defineEmits(['update:value', 'change']);

const innerValue = computed({
	get: () => props.value,
	set: val => {
		emits('update:value', val);
	}
});
const inputSearch = debounce(function (val) {
	emits('change', val);
}, 200);
</script>

<style lang="less" scoped>
.common-search {
	.search-input {
		width: 232px;

		.iconfont {
			font-size: 16px;
			color: #4e5969;
		}
	}
	/deep/.el-input__suffix {
		right: 0;
		width: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
