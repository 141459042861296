<template>
	<CLoadingBox :loading="loading" :empty="empty">
		<div class="stock-wrapper mt-3" :class="{ 'stock-wrapper--half': col == '50%' }">
			<!-- 股票代码切换Tab -->
			<StockTab :isStockComponent="true"/>
			<div class="stock-box">
				<div class="stock-box-main">
					<div class="stock-box-overview mt-27">
						<div class="overview-num">{{ formatConvertByPrice(stockQuoteData.closePrice) }}</div>
						<RiseFallRange size="14" :type="stockQuoteData.change > 0 ? 'up' : 'down'" class="ml-16" />
					</div>
					<div class="stock-box-summary mt-50">
						<div class="summary-box">
							<div
								class="summary-item"
								:class="[
									stockQuoteData.change && stockQuoteData.change > 0 ? '' : 'summary-item--down'
								]"
							>
								<span>涨跌额</span>
								<span>{{ formatConvertByPrice(stockQuoteData.change) }}</span>
								<RiseFallRange
									size="8"
									:type="stockQuoteData.change && stockQuoteData.change > 0 ? 'up' : 'down'"
									class="ml-4"
								/>
							</div>
							<div
								class="summary-item"
								:class="[
									stockQuoteData.changePct && stockQuoteData.changePct > 0 ? '' : 'summary-item--down'
								]"
							>
								<span>涨跌幅</span>
								<span>{{ formatConvertByPrice(stockQuoteData.changePct) }}%</span>
							</div>
						</div>
						<div class="summary-box">
							<div class="summary-item">
								<span>最高</span>
								<span>{{ formatConvertByPrice(stockQuoteData.highPrice) }}</span>
							</div>
							<div class="summary-item">
								<span>最低</span>
								<span>{{ formatConvertByPrice(stockQuoteData.lowPrice) }}</span>
							</div>
						</div>
					</div>
					<div class="stock-box-footer mt-72">{{ formatDateFn(stockQuoteData.tradingDay) }}</div>
				</div>
				<AbbeChat ref="refAbbeChat" class="stock-box-chat" />
			</div>
		</div>
	</CLoadingBox>
</template>

<script setup>
import { ref, computed, defineExpose, defineProps } from 'vue';
import RiseFallRange from '@/components/RiseFallRange.vue';
import AbbeChat from '../AbbeChat.vue';
import StockTab from '@/components/StockTab.vue';
import { enterprise } from '@/axios/interface';
import instance from '@/axios';
import { useStockVO, useDataState } from '@/hooks';
import { convertNumberToCNUnit } from '@/utils/api';
import dayjs from 'dayjs';

defineProps({
	col: {
		type: String,
		default: '100%'
	}
});

const { getTargetStockVO, isHKStock, isUSStock } = useStockVO();

const { data, empty, loading, setData, setLoading, setEmpty } = useDataState();
const stockQuoteData = computed(() => data.value || {});
const fetchStockQuote = async () => {
	if (isUSStock.value) {
		return setEmpty(true);
	}

	setLoading(true);
	const { market, code } = getTargetStockVO.value;
	return instance
		.get(enterprise.getStockQuote(market, code))
		.then(response => {
			setData(response.data?.data);
			setLoading(false);
		})
		.catch(() => {
			setData();
			setLoading(false);
		});
};

const formatConvertByPrice = price => {
	const { value } = convertNumberToCNUnit(price);
	return value;
};
const formatDateFn = date => {
	const formatTime = dayjs(date).valueOf();
	const formatValue = dayjs(formatTime).format('YYYY-MM-DD');
	return `截止至 ${formatValue} ${isHKStock.value ? '16:00' : '15:00'} （北京时间）`;
};

const refAbbeChat = ref();
const refreshComponent = async () => {
	await fetchStockQuote();
	if (!empty.value) {
		refAbbeChat.value && refAbbeChat.value.refresh();
	}
};

defineExpose({
	refreshComponent
});
</script>

<style lang="less" scoped>
.stock-wrapper {
	.stock-box {
		display: flex;
		justify-content: space-between;

		&-main {
			width: 392px;
		}
		&-chat {
			flex: 1;
			width: 0;
			margin-top: 36px;
		}
		&-overview {
			display: flex;
			align-items: baseline;

			.overview-num {
				color: #333;
				font-family: Barlow;
				font-size: 40px;
				font-style: normal;
				font-weight: 600;
				line-height: 140%; /* 67.2px */
			}
		}
		&-summary {
			display: flex;

			.summary-box {
				& + .summary-box {
					margin-left: 48px;
				}
			}
			.summary-item {
				//--summary-change-color: #fa4646;
				display: flex;
				align-items: baseline;

				& + .summary-item {
					margin-top: 21px;
				}
				&--down {
					//--summary-change-color: #30bf54;
				}
				span {
					&:nth-child(1) {
						color: #4e5969;
						font-family: 'PingFang SC';
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 140%; /* 22.4px */
					}
					&:nth-child(2) {
						margin-left: 4px;
						color: #333;
						font-family: Barlow;
						font-size: 24px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
				}
			}
		}
		&-footer {
			width: 100%;
			color: #86909c;
			font-family: 'PingFang SC';
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px; /* 133.333% */
		}
	}

	&--half {
		.stock-box-main {
			width: 300px;
		}
		.summary-box {
			& + .summary-box {
				margin-left: 25px !important;
			}
		}
	}
}
</style>
