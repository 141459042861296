<template>
	<div class="month-calendar-wrapper">
		<el-calendar
			v-bind:value="value"
			v-on="inputListeners"
			:first-day-of-week="7"
			operations="prev-month,today,next-month"
			hide-header
			fixed-header
			ref="elCalendarRef"
		>
			<template slot="theadCell" slot-scope="{ value }">
				<span>{{ '周' + value }}</span>
			</template>
			<template slot="dateCell" slot-scope="{ date, data }">
				<slot name="dateCell" :date="date" :data="data"></slot>
			</template>
		</el-calendar>
	</div>
</template>

<script>
export default {
	computed: {
		inputListeners: function () {
			var vm = this;
			// `Object.assign` 将所有的对象合并为一个新对象
			return Object.assign(
				{},
				// 我们从父级添加所有的监听器
				this.$listeners,
				// 然后我们添加自定义监听器，
				// 或覆写一些监听器的行为
				{
					// 这里确保组件配合 `v-model` 的工作
					input: function (val) {
						vm.$emit('update:value', val);
					}
				}
			);
		}
	}
};
</script>
<script setup>
import { ref, defineExpose } from 'vue';

defineProps({
	value: {
		type: Date
	}
});

const elCalendarRef = ref();
const selectDate = type => {
	elCalendarRef.value && elCalendarRef.value.selectDate(type);
};
defineExpose({
	selectDate
});
</script>

<style lang="less" scoped>
.month-calendar-wrapper {
	height: 100%;
}
</style>
