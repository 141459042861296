import { render, staticRenderFns } from "./ResearchReports.vue?vue&type=template&id=03c8a942&scoped=true"
import script from "./ResearchReports.vue?vue&type=script&setup=true&lang=js"
export * from "./ResearchReports.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ResearchReports.vue?vue&type=style&index=0&id=03c8a942&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03c8a942",
  null
  
)

export default component.exports