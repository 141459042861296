<template>
	<div class="file-card-wrapper">
		<div class="file-icon">
			<SvgIcons :fileUrl="calcLinkKeyName" isFile class="icon" />
		</div>
		<div class="flex-wrapper ml-16" @click="handlePreview(item)">
			<div class="content text-overflow2" :class="{ 'padding-right': shouldShowTop }">
				{{ item.title || '-' }}
			</div>
			<div class="footer mt-8">
				<div class="date" v-if="type === 'report'">
					{{ item.orgName }} | {{ item.author }} <span>{{ formatDateFn(item.time) }}</span>
				</div>
				<div class="date" v-else>{{ formatDateFn(item.time) }}</div>
				<el-button size="small" type="primary" plain
					><em class="iconfont icon-dakaimima mr-4"></em>预览</el-button
				>
			</div>
			<div v-if="shouldShowTop" class="file-top">
				<div>置顶</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import dayjs from 'dayjs';
import SvgIcons from '@/components/SvgIcons.vue';
import { handleDetail } from '@/utils/fileDetail';
import { computed } from 'vue';
import { isBoolean, isUndefined, useRoute } from '@/utils/api';

const props = defineProps({
	item: {
		type: Object,
		default() {
			return {
				url: '' //文件url
			};
		}
	},
	type: {
		type: String
	}
});

const shouldShowTop = computed(() => {
	if (!isUndefined(props.item.top)) {
		// 研报 置顶
		if (isBoolean(props.item.top)) return props.item.top;
		// 自定义模块 0是置顶
		return props.item.top == 0;
	}
	return false;
});

const transferPropToUrl = {
	report: 'url',
	announce: 'sourceLink',
	custom: 'fileUrl',
	research: 'url'
};
const calcLinkKeyName = computed(() => {
	const findKeyName = transferPropToUrl[props.type];
	const findValue = props.item[findKeyName];
	return findValue || null;
});

const formatDateFn = date => {
	return dayjs(date).format('YYYY-MM-DD');
};

const route = useRoute();
const handlePreview = info => {
	const { moduleId = '' } = route.query;
	handleDetail(info, props.type, { moduleId: moduleId });
};
</script>

<style lang="less" scoped>
.file-card-wrapper {
	padding: 20px 16px;
	display: flex;
	cursor: pointer;

	&:hover {
		background: #f7f8fa;
	}

	.file-icon {
		width: 32px;
		height: 32px;
		.icon {
			width: 32px;
			height: 32px;
		}
	}
	.flex-wrapper {
		flex: 1;
		width: 0;
		position: relative;
	}
	.file-top {
		position: absolute;
		top: 0;
		right: 0;
		height: 24px;
		display: flex;
		align-items: center;

		div {
			height: 20px;
			padding: 0 10px;
			line-height: 20px;
			background: #f23d3d;
			border-radius: 0 0 2px 2px;

			font-family: PingFang SC;
			font-size: 12px;
			font-weight: 400;
			color: #ffffff;
		}
	}

	.content {
		font-family: PingFang SC;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		color: #1d2129;
		height: 48px;

		&.padding-right {
			padding-right: 60px;
		}
	}
	.footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.date {
		font-family: PingFang SC;
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		color: #4e5969;
		span {
			margin-left: 16px;
		}
	}
}
</style>
