// 埋点自定义规则

export const getIrstoreConfig = moduleData => {
	const typeMap = {
		'/custom': '自定义模块'
	};
	const params = {
		浏览模块名称: moduleData.name,
		浏览模块类型: moduleData.path in typeMap ? typeMap[moduleData.path] : '系统默认模块'
	};
	return { params };
};
