import { throttle } from '@/utils/api';
import { onMounted, onUnmounted, nextTick, ref } from 'vue';

const getElementSize = element => {
	return new Promise(resolve => {
		requestAnimationFrame(() => {
			resolve({
				clientHeight: element.clientHeight
			});
		});
	});
};

/**
 * 用于计算并实时更新指定表格单元格内所能容纳的最大日程数量。
 * 该函数处理组件生命周期事件与窗口大小变化事件，确保在初始化时以及表格单元格尺寸变化时均进行计算。
 *
 * @param {string} options.selector - 用于标识目标表格单元格元素的CSS选择器。
 */
export const useMaxItemsFitInTableCell = ({ selector, inited }) => {
	const maxCountInCell = ref(0);
	const initDone = ref(false);

	const recalcMaxItemsInCell = () => {
		if (!selector) return;
		nextTick(() => {
			const findNd = document.querySelector(selector);
			if (!findNd) return;

			getElementSize(findNd).then(res => {
				const { clientHeight } = res;
				const singleCellContentHeight = clientHeight || 0;
				const singleScheduleHeight = 24;
				const scheduleDistance = 4;

				// 计算单个事项的总占用高度（内容高度 + 单边间距）
				const singleScheduleTotalHeight = singleScheduleHeight + scheduleDistance;
				// 初步计算最大容纳数
				const initialMaxSchedulesInCell = Math.floor(singleCellContentHeight / singleScheduleTotalHeight);
				// 计算剩余空间是否足够容纳另一个事项内容高度
				const remainingSpaceForAnotherItem =
					singleCellContentHeight - initialMaxSchedulesInCell * singleScheduleTotalHeight;

				// 根据剩余空间情况调整最大容纳数
				let maxSchedulesInCell = initialMaxSchedulesInCell;
				if (remainingSpaceForAnotherItem >= singleScheduleHeight) {
					maxSchedulesInCell++;
				}

				// 最少要展示2个事项
				maxCountInCell.value = maxSchedulesInCell >= 2 ? maxSchedulesInCell : 2;
				if (!initDone.value) {
					initDone.value = true;
					inited && inited();
				}
			});
		});
	};

	onMounted(() => {
		window.addEventListener('resize', onPageResize);
	});
	onUnmounted(() => {
		window.removeEventListener('resize', onPageResize);
	});
	const onPageResize = throttle(() => {
		nextTick(() => {
			recalcMaxItemsInCell();
		});
	}, 180);

	return {
		maxCountInCell,
		recalcMaxItemsInCell
	};
};
