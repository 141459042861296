import Empty from './Empty.vue';
import CommonSearch from '@/components/CommonSearch.vue';
import IncludeBannerCard from './IncludeBannerCard.vue';
import IncludeFileCard from './IncludeFileCard.vue';
import EmptyCover from './EmptyCover.vue';
import CommonTab from './CommonTab.vue';
import CLoadingBox from './CLoadingBox.vue';

const components = {
	Empty,
	CommonSearch,
	IncludeBannerCard,
	IncludeFileCard,
	EmptyCover,
	CommonTab,
	CLoadingBox
};

export default {
	install(Vue) {
		Object.keys(components).forEach(it => {
			Vue.component(it, components[it]);
		});
	}
};
